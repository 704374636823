/* Begin Custom Dropdown style(s) */

.page404 {
  z-index: 4;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  max-height: 100vh;
  position: relative;

  &__body {
    z-index: 4;
    width: 100%;
    height: 100vh;
    @include flexbox;
    position: absolute;
    @include padding-top(100);
    @include align-items(center);
    @include justify-content(center);
  }

  &--figure {
    z-index: 2;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    max-height: 100vh;
    position: relative;
  }

  &--overlay {
    z-index: 3;
    width: 100%;
    height: 100vh;
    content: " ";
    @include flexbox;
    position: absolute;
    @include align-items(center);
    @include justify-content(center);
    background-color: rgba($black, 0.6) !important;
  }

  &--content {
    margin: 0 auto;
    max-width: 600px;
    @include transition(all 0.5s ease);
    -webkit-animation: 1.2s ease-in-out 0s normal none 1 running fadeInDown;
    animation: 1.2s ease-in-out 0s normal none 1 running fadeInDown;

    h1 {
      color: $white;
      font-size: 70px;
    }
    p {
      color: $white;
      font-size: 16px;
      line-height: 1.5;
      @include margin-bottom(5);

      &:last-child {
        @include margin-bottom(0);
      }
    }
  }
}

/* End Custom Dropdown style(s) */
