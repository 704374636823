/* Begin Navigation style(s) */

.c-navigation {
  $navigationSelf: &;

  @include flexbox;
  text-align: center;
  @include margin-bottom(0);
  @include align-items(center);
  @include justify-content(flex-end);

  @include responsive(md) {
    height: 36px;
    @include flexbox;
  }

  &--list {
    text-align: center;
    @include margin(0);
    @include padding(0);
    @include align-items(center);

    li,
    .nav-item {
      position: relative;
      text-align: center;
      display: inline-block;
      @include margin-left(3);

      .nav-link,
      > a {
        display: block;
        cursor: pointer;
        color: $grey_sh1;
        text-shadow: none;
        @include font-size(15);
        line-height: 19px;
        @include padding(25 20);
        font-weight: $weightMedium;
        font-family: $primaryFont;
        @include transition(all 0.3s ease);

        &:hover {
          color: $white;
          background-color: $primary;
        }

        &.is--active {
          color: $white;
          background-color: $primary;
        }

        &.has-child {
          &:after {
            @extend .drop-arrow;
            content: ' ';
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23232323' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
          }

          &.active {
            &:after {
              color: $white;
            }
          }
          &:hover {
            &:after {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
            }
          }
        }

        &.is--active {
          color: $white;
          background-color: $primary;
        }
      }

      .sub-menu {
        left: 0;
        margin: 0;
        padding: 0;
        top: 120px;
        z-index: 19;
        width: 100%;
        min-width: 170px;
        max-width: 210px;
        text-align: left;
        visibility: hidden;
        position: absolute;
        @include opacity(0);
        border: 0 none !important;
        @include border-radius(0 !important);
        background: $primary none repeat scroll 0 0;
        @include transition(all 0.3s linear 0.15s);
        @include transform(translate3d(0px, 120px, 0px));
        @include transform(translate3d(0px, 0px, 0px) !important);
        @include box-shadow(0 2px 10px 6px rgba($black, 0.1) !important);

        &.show {
          @include opacity(1);
          visibility: visible;
          /*@include transform(translate3d(0px, 0px, 0px) !important);*/
        }

        &.right {
          left: auto;
          right: -14px;
        }

        > li {
          margin: 0;
          height: auto;
          display: block;
          text-align: left;
          @include padding(0);
          border: 0 none !important;
          background-color: $transparent;
          @include border-radius(0 !important);

          a {
            color: $white;
            display: block;
            text-align: left;
            position: relative;
            @include font-size(15);
            line-height: 22px;
            @include padding(12 20);
            font-weight: $weightMedium;
            text-transform: capitalize;
            @include border-radius(0 !important);
            @include transition(all 0.3s ease);
            background: $primary none repeat scroll 0 0;

            &:hover,
            &:active,
            &:focus {
              color: $white;
              border: 0px none !important;
              background: $subprimary none repeat scroll 0 0;
            }

            &.has-child {
              &:after {
                @extend .drop-arrow;
                content: '\f105';
                float: right;
                top: 3px !important;
                color: $black !important;
              }
            }

            &:hover,
            &.active {
              &.has-child {
                &:after {
                  color: $white !important;
                }
              }
            }

            &:hover {
              > a {
                &.has-child {
                  &:after {
                    color: $white !important;
                  }
                }
                color: $white;
                background: $subprimary none repeat scroll 0 0;
              }
            }

            &:last-child {
              @include border-radius(0 !important);
              a {
                @include border-radius(0 !important);
              }
            }

            &:hover .sub-menu {
              top: 0;
            }
          }
        }

        .sub-menu {
          top: 85px;
          left: 100%;
        }

        .sub-menu {
          @include border-radius(4px!important);

          > li {
            &:first-child {
              @include border-radius(0 !important);
              a {
                @include border-radius(0 !important);
              }
            }
          }
        }
      }

      &:hover {
        .nav-link,
        > a {
          color: $white;
          background-color: $primary;
          &:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
          }
        }
        .sub-menu {
          top: 69px;
          @include opacity(1);
          visibility: visible;
          display: block !important;
          @include transform(translate3d(0px, 0px, 0px) !important);
        }
      }
    }
  }

  @include responsive(md) {
    .c-navigation {
      &--list {
        width: 100%;
        @include flexbox();
        text-align: center;
        display: inline-block;
      }
    }
  }
}

.c-header {
  &.header--sticky {
    .c-navigation {
      &--list {
        li,
        .nav-item {
          .nav-link,
          > a {
            @include padding(20);
          }

          &:hover {
            .sub-menu {
              top: 59px;
            }
          }

          .sub-menu {
            > li {
              a {
                @include padding(12 20);
              }
            }
          }
        }
      }
    }
  }
}

.visible-xs-device {
  display: none;
}

body.noscroll {
  overflow: hidden !important;
  .c-responsive-standalone--heading .c-header--logo--mobile {
    z-index: 500;
    position: relative;
  }
}

@media (max-width: 767px) {
  body.noscroll {
    overflow: hidden !important;
  }
  .hidden-xs-device {
    display: none;
  }
  .visible-xs-device {
    display: block;
  }
}

/* End Navigation style(s) */
