/* Begin Custom Dropdown style(s) */
.search--filter {
  width: 100%;
  margin-top: 80px;
  background-color: $white;

  @include responsive(sm) {
    margin-top: 105px;

    @include respond-to(md) {
      margin-top: 105px;
    }
  }
}

/* End Custom Dropdown style(s) */
