/* Begin Mobile Menu style(s) */

.navSidebar {
  top: 0;
  padding: 0;
  right: auto;
  left: -240px;
  height: 100%;
  width: 240px;
  z-index: 1032;
  display: block;
  position: fixed;
  overflow: hidden;
  visibility: hidden;
  overflow-y: visible;
  background-color: $gray-900;
  @include transition(all 0.3s ease);
  @include transform(translate3d(100%, 0, 0));
  @include transform(translateZ(0));
  @include transform(translateX(0));
}

.nav-open {
  body {
    background-color: $gray-900;
  }
  .main-panel {
    overflow: hidden;
    @include padding-top(2);
  }
  .main-panel,
  .c-header {
    @include border-radii(0, 0, 0, 0);
  }
  .c-header {
    top: 0 !important;
  }
}

.widget {
  &__menu-area {
    @include flexbox;
    position: relative;
    @include padding(0 0 5);
    @include flex(1 1 auto);
    height: calc(100% - 15px);
    @include align-items(stretch);
    @include flex-direction(column, vertical);
    @include transition(opacity 0.1s ease 0.25s);

    &__content {
      @include flexbox;
      @include flex(1 1 auto);
      @include align-items(stretch);
      @include justify-content(space-between);
      @include flex-direction(column, vertical);

      &--body {
        overflow: hidden;
        position: relative;
        @include size(100%);
        @include flex(1 1 auto);

        &-scroll {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow: auto;
          overflow-x: hidden;
          position: absolute;
          @include padding-top(10);
          -webkit-overflow-scrolling: touch;
        }
      }
    }
  }
}

.responsive-navigation {
  width: 100%;
  @include margin-bottom(10);

  .nav {
    $navSelf: &;
  }

  &__list {
    &.nav {
      @include flexbox;
      @include padding(0);
      @include flex-direction(column, vertical);
    }
  }

  &__item {
    border-bottom: 1px solid rgba($white, 0.1);

    &.nav-item {
      border-bottom: 1px solid rgba($white, 0.1);
    }
  }
  .nav-link {
    color: $white;
    cursor: pointer;
    position: relative;
    @include font-size(16);
    @include padding(22 20);
    font-weight: $weightMedium;
    @include flexbox;
    @include align-self(center);
    @include align-items(center);
    @include align-content(center);
    @include justify-content(flex-start);
    @include transition(all 0.4s ease 0.25s);

    &:hover,
    &:focus,
    &:active {
      color: $primaryColor;
    }
    &:before {
      left: 0;
      bottom: 0;
      width: 0px;
      height: 3px;
      content: ' ';
      position: absolute;
      pointer-events: none;
      background-color: $white;
      @include transition(all 0.4s ease 0.25s);
    }

    &.is--active {
      color: $white;
      background-color: $primaryColor;

      &:before {
        width: 100%;
      }
    }
  }
  .dropdown {
    .nav-link {
      @include flexbox;
      @include align-items(center);
      @include justify-content(space-between);
    }
    &-menu {
      margin: 0 !important;
      padding: 0 !important;
      width: 100% !important;
      height: 0 !important;
      border: 0 none !important;
      display: block !important;
      min-width: 100% !important;
      max-width: 100% !important;
      overflow: hidden !important;
      position: relative !important;
      @include border-radius(0px !important);
      background-color: transparent !important;
      @include transition(all 0.3s ease !important);
      @include transform(translate3d(0, 0, 0) !important);
    }

    &-item {
      padding: 1rem 1.5rem;
      padding-left: 2.5rem;
      background-color: lighten($gray-900, 10%);
      border-bottom: 1px solid lighten($gray-900, 7.5%);

      &:last-child {
        border-bottom: 0 none !important;
      }
    }

    &.show {
      .dropdown-menu {
        height: auto !important;
      }
    }
  }
}

/* End Mobile Menu style(s) */
