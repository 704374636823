/* Begin Header style(s) */

.page--breadcrumb {
  bottom: 0;
  position: absolute;
  display: none !important;

  &__inner {
    width: 100%;
    @include inline-flex;
    @include align-items(center);
    @include justify-content(flex-start);
  }
  .breadcrumb {
    border-radius: 0;
    @include margin(0);
    @include padding(20 20 10 20);
    @include inline-flex;
    background-color: $white;
    @include align-items(center);
    @include justify-content(flex-start);

    > li {
      color: $grey;
      position: relative;
      @include inline-flex;

      &::after {
        content: "/";
        @include margin-left(10);
        @include margin-right(10);
      }
      &::before {
        display: none;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      > a {
        color: $black;
        display: block;

        &:hover,
        &:focus,
        &:active {
          color: $hoverColor;
        }
      }
    }
  }
}

/* End Header style(s) */
