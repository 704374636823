/* Forms
------------------------------------*/
.errorIcon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FC1D4C' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FC1D4C' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.alert {
  font-weight: $weightMedium;
  font-family: $secondaryFont;
  p {
    font-size: 16px;
    font-weight: $weightMedium;
    font-family: $secondaryFont;
    text-transform: none !important;
  }
}
// Firfox fixes
@-moz-document url-prefix() {
  .alert {
    p {
      @include padding-top(6);
    }
  }
}

.form-control::-webkit-input-placeholder {
  color: rgba($greyish, 0.5);
}
.form-control::-moz-placeholder {
  color: rgba($greyish, 0.5);
}
.form-control::-ms-input-placeholder {
  color: rgba($greyish, 0.5);
}
.form-control::-o-placeholder {
  color: rgba($greyish, 0.5);
}

.form-group {
  @include margin-bottom(15);

  abbr {
    cursor: pointer;
    border: 0px none;
    text-decoration: none;

    &.required {
      color: $red;
    }
  }

  label {
    cursor: pointer;
    color: $black_sh1;
    @include font-size(16);
    @include margin-bottom(7);
    text-transform: capitalize;
    font-weight: $weightRegular;

    &.leftAlign {
      width: 100%;
      text-align: left;
    }
    &.rightAlign {
      width: 100%;
      text-align: left;
    }

    @include breakpoint(640) {
      @include font-size(16);
    }
  }

  .valid-feedback,
  .invalid-feedback {
    @include margin-top(8);
  }

  .form-control {
    height: auto;
    color: $black;
    width: $viewportSize;
    @include padding(12);
    @include font-size(15);
    filter: none !important;
    border: 1px solid $border;
    @include border-radius(0);
    font-family: $secondaryFont;
    font-weight: 400 !important;
    @include transition(all 0.3s ease-out);
    @include box-shadow(2px 2px 4px rgba($border, 0.2));

    &.solid {
      background-color: $white;
      border: 1px solid $border;
    }
    &.rounded {
      @include border-radius(3px);
    }
    &:focus {
      border: 1px solid $blue;
      box-shadow: 0 0 0 0.15rem rgba($blue, 0.25) !important;

      &.solid {
        border: 1px solid $blue;
      }
    }

    &:focus {
      outline: 0;
      color: $black;
      border-color: $blue;
      background-color: $white;
      @include box-shadow(0 0 0 0.15rem rgba($blue, 0.25));
    }

    &[type="email"],
    &[type="password"],
    &[type="text"]#ZIP,
    &[type="text"]#zip {
      text-transform: none !important;
    }

    &[readonly="readonly"] {
      cursor: not-allowed !important;
    }

    &.big {
      height: 60px;
    }
  }

  textarea {
    resize: none;
    min-height: 100px;
    @include font-size(15);

    &.big {
      min-height: 140px !important;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:active,
  input:-webkit-autofill:focus {
    color: $black !important;
    @include background-clip(text !important);
    /*-webkit-text-fill-color: $white !important;
        @include text-fill-color($white !important);*/
    @include box-shadow(2px 2px 4px rgba($border, 0.2));
    @include linear-gradient(to left, $white, $white !important);
  }

  input,
  select {
    &.form-control {
      height: auto;
    }
  }

  .help-block {
    display: none;
  }

  &--has-error {
    & label {
      color: $error;
    }
    .form-control {
      border: 1px solid $error;
      background-color: $white;
      @include padding-right(30);
      @include box-shadow(0 0 0 0.15rem rgba($error, 0.15));

      &.solid {
        border: 1px solid $error;
        background-color: $white;
        @include box-shadow(0 0 0 0.15rem rgba($error, 0.15));
      }
    }

    .form-control {
      @extend .errorIcon;

      &.ng-invalid,
      &.dirty,
      &:invalid {
        @extend .errorIcon;
      }
    }
    textarea {
      &.form-control,
      &.ng-invalid,
      &.dirty,
      &:invalid {
        background-position: right calc(0.375em + 0.1875rem) top
          calc(0.375em + 0.1875rem) !important;
      }
    }

    .form-control::-webkit-input-placeholder {
      color: rgba($error, 0.5);
    }
    .form-control::-moz-placeholder {
      color: rgba($error, 0.5);
    }
    .form-control::-ms-input-placeholder {
      color: rgba($error, 0.5);
    }
    .form-control::-o-placeholder {
      color: rgba($error, 0.5);
    }

    .help-block {
      width: auto;
      color: $white;
      position: relative;
      @include padding(2 8);
      @include margin-top(3);
      @include font-size(12);
      line-height: 1.25;
      background-color: $error;
      @include border-radius(4px);
      display: inline-block !important;

      &::before {
        top: -9px;
        left: 9px;
        content: "";
        @include size(0px);
        position: absolute;
        border-style: solid;
        border-width: 9px 0 0 9px;
        border-color: transparent transparent transparent $error;
      }
    }
    @-moz-document url-prefix() {
      .help-block {
        @include padding(8 8 0);
      }
    }
  }
  &--has-success {
    & label {
      color: $success;
    }
    & .form-control {
      border: 1px solid $success;
    }
    & .help-block {
      color: $success;
    }
  }
}

input[readonly="readonly"] {
  cursor: not-allowed !important;
}


/* Custom Radio and Checkboxes
------------------------------------- */
.form-check.checkbox {
  padding-left: 0;
}
.checkbox {
  height: auto;
  @include flexbox;
  min-height: 22px;
  @include align-items(center);

  label {
    height: auto;
    min-height: 22px;
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 0;
    color: $black_sh1;
    position: relative;
    display: inline-block;
    @include font-size(16);
    line-height: 22px;
    @include padding-left(30);
    font-weight: $weightMedium;
    text-transform: inherit !important;
    @include flexbox;
    @include align-items(center);
    @include justify-content(flex-start);
    max-width: 100%;
    @include flex(0 0 100%);

    strong {
      color: $black_sh1;
      @include margin-left(4);
    }

    span.text-green,
    a {
      @include margin-left(4);
    }

    &:before {
      top: 0;
      left: 0;
      content: " ";
      margin-left: 0;
      position: absolute;
      outline: 0 !important;
      @include size(22px);
      display: inline-block;
      background-color: $white;
      border: 1px solid $border;
      @include border-radius(2px);
      @include transition(0.3s ease-in-out);
    }

    &:after {
      top: 0;
      left: 0;
      margin-left: 0;
      color: $greyish;
      padding-top: 2px;
      padding-left: 3px;
      position: absolute;
      @include size(22px);
      display: inline-block;
      @include font-size(14);
    }
  }

  input[type="checkbox"] {
    z-index: 1;
    cursor: pointer;
    outline: 0 !important;
    @include opacity(0);
    position: absolute;
    @include size(1px);

    &:disabled + label {
      @include opacity(0.65);
    }

    &:focus + label::before {
      outline: 0;
      outline-offset: -2px;
    }
    &:checked + label::before {
      border-color: $blue_sh1;
      background-color: $blue_sh1;
    }
    &:checked + label::after {
      top: 3px;
      left: 8px;
      content: "";
      display: table;
      position: absolute;
      @include size(7px, 12px);
      border: 2px solid $white;
      border-top-width: 0;
      border-left-width: 0;
      @include transform(rotate(45deg));
    }

    &:disabled + label::before {
      cursor: not-allowed;
      background-color: $white;
    }
  }

  &-primary {
    input[type="checkbox"]:checked + label::before {
      border-color: darken($border, 10%);
      background-color: darken($border, 10%);
    }
    input[type="checkbox"]:checked + label::after {
      border-color: $black;
    }
  }
}

.radio {
  height: auto;
  @include flexbox;
  @include align-items(center);

  label {
    height: auto;
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 0;
    color: $black_sh1;
    position: relative;
    padding-left: 16px;
    display: inline-block;
    @include font-size(16);
    line-height: 1.5;
    font-weight: $weightMedium;
    text-transform: inherit !important;
    max-width: 100%;
    @include flex(0 0 100%);

    strong {
      color: $black_sh1;
      @include padding-left(4);
    }

    span.text-green,
    a {
      @include padding-left(4);
    }

    &:before {
      left: 0;
      background-color: $white;
      border-radius: 50%;
      border: 2px solid $border;
      content: " ";
      display: inline-block;
      @include margin-left(-12);
      position: absolute;
      @include transition(border 0.5s ease-in-out);
      @include size(22px);
      outline: 0 !important;
    }

    &:after {
      top: 5px;
      left: 5px;
      -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -webkit-transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s
        cubic-bezier(0.8, -0.33, 0.2, 1.33);
      background-color: #6c757d;
      border-radius: 50%;
      content: " ";
      display: inline-block;
      @include margin-left(-12);
      position: absolute;
      transform: scale(0, 0);
      transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33),
        -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      @include size(12px);
    }
  }

  input[type="radio"] {
    z-index: 1;
    cursor: pointer;
    @include opacity(0);
    outline: 0 !important;
    position: absolute;
    @include size(1px);
  }
  input[type="radio"]:disabled + label {
    @include opacity(0.65);
  }
  input[type="radio"]:focus + label::before {
    outline-offset: -2px;
    outline: 5px auto -webkit-focus-ring-color;
    outline: thin dotted;
  }
  input[type="radio"]:checked + label::after {
    @include transform(scale(1, 1));
  }
  input[type="radio"]:disabled + label::before {
    cursor: not-allowed;
  }

  &-right {
    label {
      padding-left: 0px;
      padding-right: 26px;

      &:before {
        top: 1px;
        right: 0;
        left: auto;
        margin-left: 0;
        margin-right: 0px;
        position: absolute;
        @include transition(border 0.5s ease-in-out);
        width: 18px;
        outline: 0 !important;
      }
      &:after {
        top: 5px;
        left: auto;
        right: 4px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    input[type="radio"] {
      position: absolute;
    }
  }
}

.radio.radio-inline {
  margin-top: 0;
}

.radio.radio-single label {
  height: 17px;
}

.radio-primary input[type="radio"] + label::after {
  background-color: $blue_sh1;
}

.radio-primary input[type="radio"]:checked + label::before {
  border-color: $blue_sh1;
}

.radio-primary input[type="radio"]:checked + label::after {
  background-color: $blue_sh1;
}
