.site-content {
  z-index: 2;
  flex: 1 0 auto;
  position: relative;

  &::before {
    top: 0;
    left: 0;
    z-index: 1;
    content: "";
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    position: absolute;
    background-color: $black !important;
    display: none !important;

    @media (max-width: 767.99px) {
      max-height: 600px;
    }
  }
}

.hero--banner {
  z-index: 4;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  max-height: 100vh;
  position: relative;
  background-color: $black !important;

  &__body {
    width: 100%;
    height: 100vh;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    background-color: $black !important;
  }

  .slick-slider {
    width: 100%;
    height: 100vh;
    max-height: 100vh;

    .slick-slide {
      -webkit-transition: opacity 6000ms ease 0s, visibility 6000ms ease 0s !important;
      -moz-transition: opacity 6000ms ease 0s, visibility 6000ms ease 0s !important;
      -ms-transition: opacity 6000ms ease 0s, visibility 6000ms ease 0s !important;
      -o-transition: opacity 6000ms ease 0s, visibility 6000ms ease 0s !important;
      transition: opacity 6000ms ease 0s, visibility 6000ms ease 0s !important;

      .img-fluid {
        width: 100%;
        height: 100vh;
        max-height: 100vh;
        @extend .img-cover;
      }
    }
  }
  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100vh;
    max-height: 100vh;
  }
  .slick-prev,
  .slick-next {
    z-index: 2;
    @include flexbox;
    position: absolute;
    @include size(40px);
    font-size: 0 !important;
    @include border-radius(3px);
    @include align-items(center);
    @include justify-content(center);
    @include transition(all 0.5s ease);
    background-color: rgba($black, 0.8);
    display: flex !important;
    @include opacity(0);
    visibility: hidden;
    display: none !important;

    &::before {
      content: " ";
      color: $white;
      display: block;
      font-size: 20px;
      position: absolute;
      text-align: center;
      @include size(20px);
      @include transition(all 0.5s ease);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' fill='%23fff'%3E%3Cpath d='M79.093 0L48.907 30.187 146.72 128l-97.813 97.813L79.093 256l128-128z'/%3E%3C/svg%3E");
    }

    &:hover,
    &:focus,
    &:active {
      color: $black;
      background-color: $hoverColor;

      &::before {
        color: $black;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' fill='%23000'%3E%3Cpath d='M79.093 0L48.907 30.187 146.72 128l-97.813 97.813L79.093 256l128-128z'/%3E%3C/svg%3E");
      }
    }
  }
  .slick-prev {
    left: 30px;
    right: auto;

    @media (max-width: 767.99px) {
      left: 10px;
      display: none !important;
    }
  }
  .slick-next {
    left: auto;
    right: 30px;

    @media (max-width: 767.99px) {
      right: 10px;
      display: none !important;
    }
  }
  .slick-prev {
    &::before {
      @include transform(rotate(180deg));
    }
  }

  .slick-dots {
    bottom: 20px !important;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);

    li {
      @include size(8px);
      @include transition(all 0.5s ease);

      button {
        @include size(8px);
        @include padding(0);
        @include transition(all 0.5s ease);

        &::before {
          @include size(8px);
          font-size: 0 !important;
          background-color: $black;
          @include border-radius(5px);
          @include transition(all 0.5s ease);
        }
      }

      &.slick-active {
        width: 20px;

        button {
          width: 20px;

          &::before {
            width: 20px;
            background-color: $white;
          }
        }
      }
    }
  }

  &:hover {
    .slick-prev,
    .slick-next {
      @include opacity(1);
      visibility: visible;
    }
  }
}

.hero--content {
  background-color: rgba($black, 0.35);
}
.hero--inner__content,
.hero--content {
  z-index: 2;
  width: 100%;
  height: 100%;
  @include flexbox;
  position: absolute;
  text-align: center;
  @include padding-top(180);
  @include align-items(center);
  @include justify-content(center);
  @include flex-direction(column, vertical);

  h1,
  h4 {
    color: $white;
    letter-spacing: 2px;
    text-transform: $uppercase;
    font-family: $secondaryFont;
    font-weight: $weightRegular;
  }

  h4 {
    font-size: 12px;
    line-height: 1;
    @include margin-bottom(10);
  }
  h1 {
    color: $white;
    font-size: 32px;
    line-height: 1;
    @include margin-bottom(35);
  }
}

.hero--buttons {
  @include flexbox;
  @include align-items(center);

  .c-button + .c-button {
    margin-left: 15px;
  }
  .c-button {
    height: auto;
    color: $white;
    font-size: 14px;
    border-radius: 0;
    letter-spacing: 2px;
    @include padding(12 30);
    font-weight: $weightSemibold !important;

    &--white {
      color: $white;
      background-color: transparent !important;

      &:hover,
      &:focus,
      &:active {
        color: $black;
        background-color: $white !important;
      }
    }
  }
}
// .hero--banner {
//   h4,
//   h1,
//   .hero--buttons {
//     visibility: hidden;
//     @include opacity(0);
//     @include transition-delay(1000ms);
//     @include transition(transform 2000ms ease, opacity 2000ms ease);
//   }

//   h4 {
//     @include transform(translateY(50px));
//   }
//   h1 {
//     @include transform(translateY(100px));
//   }
//   .hero--buttons {
//     @include transform(translateY(150px));
//   }

//   .slick-slider {
//     .slick-slide {
//       &.slick-active {
//         h4,
//         h1,
//         .hero--buttons {
//           visibility: visible;
//           @include opacity(1);
//           @include transform(translateY(0) translateX(0));
//         }
//       }
//     }
//   }
// }

.video-block {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  pointer-events: none;

  video,
  iframe {
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@-webkit-keyframes video {
  0% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@keyframes video {
  0% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.hero-figure {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  .img-fluid {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    @extend .img-cover;
  }

  @media (max-width: 767.99px) {
    .img-fluid {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      object-position: center;
    }
  }
}

@media (max-width: 767.99px) {
  .hero--banner {
    max-height: 500px;

    &__body {
      max-height: 500px;
    }
    .slick-slider {
      max-height: 500px;
    }
    .slick-slider {
      max-height: 500px;
    }
    .slick-list,
    .slick-track,
    .slick-slide {
      max-height: 500px;

      img,
      .img-fluid {
        width: 100%;
        height: 500px;
        max-height: 500px;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .hero--content {
    text-align: center;
    @include padding-top(70);

    h4 {
      font-size: 12px;
    }
    h1 {
      font-size: 30px;
      line-height: 1;
    }
  }
  .hero--buttons {
    .c-button + .c-button {
      margin-left: 10px;
    }
    .c-button {
      @include padding(11 18);
    }
  }
}

.testimonial--hero__banner {
  height: 350px;
  overflow: hidden;
  max-height: 350px;
  background-color: $black !important;

  @media (max-width: 767.99px) {
    height: 200px;
    max-height: 200px;
  }
  @media screen and (min-width: 768px) and (max-width: 991.99px) {
    height: 250px;
    max-height: 250px;
  }

  .hero--banner {
    height: 350px;
    max-height: 350px;

    @media (max-width: 767.99px) {
      height: 200px;
      max-height: 200px;
    }
    @media screen and (min-width: 768px) and (max-width: 991.99px) {
      height: 250px;
      max-height: 250px;
    }

    &__body {
      height: 350px;
      max-height: 350px;

      @media (max-width: 767.99px) {
        height: 200px;
        max-height: 200px;
      }
      @media screen and (min-width: 768px) and (max-width: 991.99px) {
        height: 250px;
        max-height: 250px;
      }
    }
  }
  .hero--inner__content,
  .hero--content {
    max-height: 350px;
    @include padding-top(85);

    @media (max-width: 767.99px) {
      max-height: 200px;
      @include padding-top(50);
    }
    @media screen and (min-width: 768px) and (max-width: 991.99px) {
      max-height: 250px;
      @include padding-top(60);
    }
  }

  .hero--content > div {
    display: none !important;
  }

  .hero-figure {
    max-height: 350px;

    .img-fluid {
      width: 100%;
      height: 350px;
      max-height: 350px;
      @extend .img-cover;
    }

    @media (max-width: 767.99px) {
      .img-fluid {
        width: 100%;
        height: 200px;
        max-height: 200px;
        object-fit: cover;
        object-position: center;
      }
    }
    @media screen and (min-width: 768px) and (max-width: 991.99px) {
      .img-fluid {
        width: 100%;
        height: 250px;
        max-height: 250px;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

body {
  &.about-us {
    .testimonial--hero__banner .hero--content {
      @include padding-top(50);
    }
  }

  &.seller,
  &.seller-page,
  &.sellers,
  &.sellers-page,
  &.find-a-seller,
  &.find-a-sellers {
    .testimonial--hero__banner {
      height: 75vh;
      overflow: hidden;
      max-height: 75vh;

      .hero-figure,
      .hero--content,
      .hero--banner__body,
      .hero--inner__content {
        height: 75vh;
        max-height: 75vh;
      }
      .hero-figure {
        .img-fluid {
          width: 100%;
          height: 75vh;
          max-height: 75vh;
          @extend .img-cover;
        }
      }
      .hero--content {
        > div {
          display: flex !important;
        }
        h1 {
          margin-bottom: 1.5rem !important;
        }
      }
    }
  }
  &.faqs {
    .testimonial--hero__banner {
      .hero--content {
        @include linear-gradient(
          to bottom,
          rgba($black, 0.5) 0%,
          rgba($black, 0) 50%,
          rgba($black, 0) 100%
        );
      }
    }
  }
}
.hero--inner__content {
  pointer-events: none;
  .page--breadcrumb {
    pointer-events: all;
  }
}
