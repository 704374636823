//=Loading style(s) 
//Variable(s) must be driven from _variables.scss


.loading {
    $loadingSelf: &;
    
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    position: fixed;
    @include flexbox;
    @include size(100%);
    @include align-items(center);
    @include justify-content(center);
    background-color: rgba($black, 0.75);
    
    .sk-cube-grid {
        top: 50%;
        left: 50%;
        margin: 0px auto;
        position: absolute;
        @include size(50px);
        @include transform(translate(-50%, -50%));
    }

    .sk-cube-grid .sk-cube {
        width: 33%;
        height: 33%;
        float: left;
        background-color: $primary;
        -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
        animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    }

    .sk-cube-grid .sk-cube1 {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }

    .sk-cube-grid .sk-cube2 {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }

    .sk-cube-grid .sk-cube3 {
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
    }

    .sk-cube-grid .sk-cube4 {
        -webkit-animation-delay: 0.1s;
        animation-delay: 0.1s;
    }

    .sk-cube-grid .sk-cube5 {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }

    .sk-cube-grid .sk-cube6 {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }

    .sk-cube-grid .sk-cube7 {
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }

    .sk-cube-grid .sk-cube8 {
        -webkit-animation-delay: 0.1s;
        animation-delay: 0.1s;
    }

    .sk-cube-grid .sk-cube9 {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }

    @-webkit-keyframes sk-cubeGridScaleDelay {
        0%,
        70%,
        100% {
            @include transform(scale3D(1, 1, 1));
        }
        35% {
            @include transform(scale3D(0, 0, 1));
        }
    }

    @keyframes sk-cubeGridScaleDelay {
        0%,
        70%,
        100% {
            @include transform(scale3D(1, 1, 1));
        }
        35% {
            @include transform(scale3D(0, 0, 1));
        }
    }
    
    &--block {
        top: 0;
        left: 50%;
        z-index: 14;
        position: absolute;
        color: $primaryColor;
        @include font-size(14);
        line-height: 1.35;
        box-shadow: $box-shadow;
        background-color: $white;
        @include padding(5 30 6);
        @include padding-left(50);
        font-weight: $weightMedium;
        @include border-radius(0 0 6px 6px);
        @include transform(translateX(-50%));
    }
}


.inlineloading {
    &:after {
        top: 50%;
        left: 15px;
        content: "";
        position: absolute;
        @include size(16px);
        @include opacity(1);
        display: inline-block;
        vertical-align: middle;
        @include margin-top(-8);
        border: 1.5px solid $white;
        @include border-radius(50%);
        @include transition(opacity .2s ease);
        border-left-color: lighten($black, 20%);
        animation: load-spin 450ms infinite linear;
        -webkit-animation: load-spin 450ms infinite linear;
    }
    
    &.btn,
    &.button {
        /*min-width: 52px;
        font-size: 0px !important;*/
    }
}
    
.loading--block {
    .inlineloading {
        &:after {
            left: 25px;
            @include margin-top(-9);
            border: 1.5px solid $primaryColor;
            border-left-color: lighten($white, 20%);
        }
    }
}


@keyframes load-spin {
    100% {
        @include transform(rotate(360deg));
    }
}
@-webkit-keyframes load-spin {
    100% {
        @include transform(rotate(360deg));
    }
}
@keyframes fadeIn {
    from {
        @include opacity(0);
    }
    to {
        @include opacity(1);
    }
}
@keyframes fadeInLeftShort {
    from {
        @include transform(translate3d(-20px, 0, 0));
    }
    to {
        @include transform(none);
    }
}
@-webkit-keyframes fadeInLeftShort {
    from {
        @include transform(translate3d(-20px, 0, 0));
    }
    to {
        @include transform(none);
    }
}
@-webkit-keyframes fadeOutLeft {
    from {
        @include transform(none);
    }
    to {
        @include transform(translate3d(-50px, 0, 0));
    }
}
