/* Begin Footer style(s) */
$dark-gray: #808284 !important;

.pre--footer {
  $preFooterSelf: &;

  z-index: 2;
  color: $dark-gray;
  @include font-size(15);
  position: relative;
  @include padding(40 0);
  background-color: $card;
  border-bottom: 3px solid $yellow;

  @include responsive(sm) {
    @include padding(45 0 40);

    @include respond-to(md) {
      text-align: left;
      @include padding(50 0 45);
    }
  }

  h2 {
    color: $white;
    font-size: 20px;
    line-height: 24px;
    @include margin-bottom(20);
  }

  &__links {
    width: 100%;
    @include flexbox;
    @include flex-wrap(wrap);
    @extend .animation-all;
    @include justify-content(space-between);

    > ul {
      width: auto;
      flex: 0 0 25%;
      max-width: 25%;
      @include margin-bottom(0);

      @include breakpoint(480.99) {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      @media screen and (min-width: 481px) and (max-width: 700px) {
        width: 100%;
        flex: 0 0 50%;
        max-width: 50%;
      }

      li {
        font-size: 15px;
        line-height: 1.5;
        @include margin-bottom(15);

        &:last-child {
          @include margin-bottom(0);
        }

        a {
          font-weight: 400;
          color: $dark-gray;
          display: inline-block;
          @include padding-left(0);
          @extend .animation-all;

          &:hover {
            color: $hoverColor;
            @include padding-left(10);
          }
        }
      }
    }
  }
}

.site--footer {
  $footerSelf: &;

  width: 100%;
  text-align: left;
  color: $dark-gray;
  @include font-size(15);
  line-height: 1.25;
  @include padding(40 0);
  background-color: $black;

  @include responsive(sm) {
    @include padding(45 0);

    @include respond-to(md) {
      text-align: left;
      @include padding(75 0);
    }
  }

  .footer--logo {
    display: block;
    margin: 0 0 20px;
    width: $logo-width;
    max-width: $logo-width;

    @include responsive(md) {
      margin: 0;
      @include respond-to(lg) {
        margin: 0;
      }
    }

    .page--logo {
      max-width: $logo-width;

      @include breakpoint(767) {
        max-width: $logo-small-width;
      }
    }
  }

  @include breakpoint(767) {
    font-size: 0.938rem;
  }

  a {
    color: $dark-gray;
    position: relative;
    font-weight: $weightBold;
    transition: all ease-in-out 0.3ms;

    @include breakpoint(767) {
      font-weight: $weightMedium;
    }
  }

  .last {
    a {
      &:first-child {
        &:before {
          display: none;
        }
      }
    }

    @include breakpoint(767) {
      text-align: center !important;

      a {
        &:first-child {
          @include margin-left(0);
        }
        &:last-child {
          @include margin-right(0);
        }
      }
    }
  }

  &__bottom {
    color: $dark-gray;
    @include padding(25 0);
    background-color: $white;

    @include responsive(sm) {
      @include padding(30 0);
    }
  }
}

.footer-copyright {
  color: $dark-gray;
  padding-top: 10px;

  a {
    color: $dark-gray;
    font-weight: 600;
    @extend .animation-all;

    &:hover {
      color: $hoverColor;
    }
  }
}

.footer--title {
  z-index: 2;
  color: $white;
  font-size: 22px;
  font-weight: 600;
  position: relative;
  @include margin-bottom(15);

  &::before {
    top: 0;
    left: 0;
    z-index: -1;
    width: 200px;
    height: 30px;
    content: " ";
    max-width: 200px;
    position: absolute;
    pointer-events: none;
    @include opacity(0.5);
    @include transform(translateX(0));
    background-image: url("../../images/pattern.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 200px auto;
    display: none !important;
  }
}

.footer--social,
.footer--nav,
.footer--address {
  .list-reset {
    list-style: none;
    @include margin(0);
    @include padding(0);

    li {
      font-size: 15px;
      line-height: 1.5;
      @include margin-bottom(15);

      &:last-child {
        @include margin-bottom(0);
      }

      a {
        font-weight: 400;
        color: $dark-gray;
        display: inline-block;
        @include padding-left(0);
        @extend .animation-all;

        &:hover,
        &:focus,
        &:active {
          color: $hoverColor;
          @include padding-left(10);
        }
      }
    }
  }
}
.footer--address {
  .list-reset {
    li {
      max-width: 250px;
    }
  }
}
.footer--nav {
  .list-reset {
    li {
      @include margin-bottom(10);
      &:last-child {
        @include margin-bottom(0);
      }
      > a {
        position: relative;

        &::before {
          left: -10px;
          top: 0.25rem; /* 4px */
          content: " ";
          display: block;
          color: $dark-gray;
          position: absolute;
          visibility: hidden;
          @include opacity(0);
          @include size(0.938rem); /* 15px */
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%23848A9F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' viewBox='0 0 24 24'%3E%3Cpath d='M13 17l5-5-5-5M6 17l5-5-5-5'/%3E%3C/svg%3E");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 0.938rem auto;
          @extend .animation-all;
        }
        &:hover,
        &:focus,
        &:active {
          @include padding-left(15);

          &::before {
            left: -2px;
            visibility: visible;
            @include opacity(1);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%23E3C87D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' viewBox='0 0 24 24'%3E%3Cpath d='M13 17l5-5-5-5M6 17l5-5-5-5'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }
}
.footer--social {
  .list-reset {
    @include flexbox;
    @include align-items(center);
    @include justify-content(flex-end);

    li {
      @include margin-left(30);
      @include margin-bottom(0);

      &:first-child {
        @include margin-left(0);
      }

      a {
        color: $dark-gray;
        @include padding-left(0);

        &:hover {
          @include padding-left(0);
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .footer--title {
    @include margin-top(35);
  }
  .site--footer {
    &__bottom {
      text-align: left;
    }
  }
  .footer--social {
    @include margin-top(20);

    .list-reset {
      @include justify-content(flex-start);
    }
  }
}

/* End Footer style(s) */
