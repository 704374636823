//=Modal style(s)
//Variable(s) must be driven from _variables.scss

.x--close {
  top: 18px;
  right: 18px;
  font-size: 30px;
  position: absolute;
  @include opacity(1);
  color: $primaryColor;
  font-weight: $weightLight;
  margin-left: inherit !important;

  &:hover,
  &:focus,
  &:active {
    color: $primary;
  }
}

.modal {
  $modalSelf: &;

  &-dialog {
    height: 100%;
    @include flexbox;
    @include align-items(flex-start);
    @include justify-content(center);
    margin-top: 1.35rem !important;
    margin-bottom: 1.25rem !important;

    @include breakpoint(991) {
      @include align-items(flex-start);
    }

    // Size(s)
    &.modal--fullwidth {
      min-width: 300px !important;

      @include responsive(md) {
        min-width: calc(100% - 50px) !important;
        max-width: calc(100% - 50px) !important;
      }

      &[data-bg] {
        .modal-content {
          position: relative;
          background-color: $white_sh2 !important;

          &:before {
            top: 0px;
            left: 50%;
            width: 1px;
            height: 100%;
            content: " ";
            position: absolute;
            pointer-events: none;
            background-color: $border;

            @include breakpoint(991) {
              display: none;
            }
          }
        }
      }

      &.gray {
        .modal-content {
          background-color: #f9f9f9 !important;

          &:before {
            top: 0px;
            left: 50%;
            width: 1px;
            height: 100%;
            content: " ";
            position: absolute;
            pointer-events: none;
            background-color: $border;

            @include breakpoint(991) {
              display: none;
            }
          }
        }
      }
    }

    &.modal--exlarge {
      min-width: 300px !important;

      @include responsive(md) {
        min-width: 950px !important;
        max-width: 950px !important;
      }
    }

    &.modal--large {
      min-width: 300px !important;

      @include responsive(md) {
        min-width: 750px !important;
        max-width: 750px !important;
      }
    }

    &.modal--medium {
      min-width: 300px !important;

      @include responsive(md) {
        min-width: 580px !important;
        max-width: 580px !important;
      }
    }

    &.modal--small {
      min-width: 300px !important;

      @include responsive(md) {
        min-width: 450px !important;
        max-width: 450px !important;
      }
    }
  }

  // Header
  &-header {
    &.center {
      text-align: center;
      @include justify-content(center !important);
    }

    .close {
      @extend .x--close !optional;
    }

    #{$modalSelf}-title {
      color: $black;
      text-align: left;
      @include font-size(20);
      line-height: 1.45;
      font-family: $primaryFont;
      @include margin-bottom(0);
      letter-spacing: -1px !important;
      font-weight: $weightBold !important;
    }

    &.plr--30 {
      @include padding-left(15);
      @include padding-right(15);

      @include responsive(sm) {
        @include padding-left(20);
        @include padding-right(20);

        @include respond-to(md) {
          @include padding-left(20);
          @include padding-right(20);

          @include respond-to(lg) {
            @include padding-left(30);
            @include padding-right(30);
          }
        }
      }
    }

    &.plr--45 {
      @include padding-left(20);
      @include padding-right(20);
      padding-bottom: 0px !important;

      @include responsive(sm) {
        @include padding-left(25);
        @include padding-right(25);

        @include respond-to(md) {
          @include padding-left(35);
          @include padding-right(35);

          @include respond-to(lg) {
            @include padding-left(45);
            @include padding-right(45);
          }
        }
      }
    }

    &.plr--60 {
      @include padding-left(25);
      @include padding-right(25);

      @include responsive(sm) {
        @include padding-left(30);
        @include padding-right(30);

        @include respond-to(md) {
          @include padding-left(45);
          @include padding-right(45);

          @include respond-to(lg) {
            @include padding-left(60);
            @include padding-right(60);
          }
        }
      }
    }

    &--inner {
      @include flexbox;
      @include align-items(center);
      @include justify-content(flex-start);
    }

    &.u-radius--0 {
      border: 0px none !important;
    }
    &.noStyle {
      border: 0px none !important;
    }
  }

  // Body
  &-body {
    /*overflow-y: auto;
        max-height: 550px;*/

    .close {
      @extend .x--close !optional;
      top: 13px;
    }

    &.plr--60 {
      @include padding-left(25);
      @include padding-right(25);

      @include responsive(sm) {
        @include padding-left(30);
        @include padding-right(30);

        @include respond-to(md) {
          @include padding-left(45);
          @include padding-right(45);

          @include respond-to(lg) {
            @include padding-left(60);
            @include padding-right(60);
          }
        }
      }
    }

    &.plr--30 {
      @include padding-left(15);
      @include padding-right(15);

      @include responsive(sm) {
        @include padding-left(20);
        @include padding-right(20);

        @include respond-to(md) {
          @include padding-left(20);
          @include padding-right(20);

          @include respond-to(lg) {
            @include padding-left(30);
            @include padding-right(30);
          }
        }
      }
    }

    &.plr--45 {
      @include padding-left(20);
      @include padding-right(20);

      @include responsive(sm) {
        @include padding-left(25);
        @include padding-right(25);

        @include respond-to(md) {
          @include padding-left(35);
          @include padding-right(35);

          @include respond-to(lg) {
            @include padding-left(45);
            @include padding-right(45);
          }
        }
      }
    }

    &--inner {
      @include padding(15 0);

      &.pt--30 {
        @include padding-top(35);
      }
    }

    &--header {
      max-width: inherit;

      &.center {
        @include flexbox;
        max-width: 475px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        @include margin-bottom(30);
        @include align-items(center);
        @include justify-content(center);
        @include flex-direction(column, vertical);

        .title {
          color: $gray-900;
          letter-spacing: -1px;
          @include font-size(30);
          font-weight: $weightBold;
        }

        .fs--15 {
          color: $dark-gray;
          @include font-size(15);
          line-height: 1.35;
        }

        .btn--upload {
          color: $primaryColor;
          margin-left: auto !important;
          margin-right: auto !important;
          background-color: transparent;
        }
        .btn {
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }
    }

    .inline--form__option {
      &.right {
        top: auto;
        right: auto;
        position: relative;
        transform: translateY(none);

        .css-1wa3eu0-placeholder,
        .css-1uccc91-singleValue,
        select.form-control {
          text-transform: none;
        }
      }
    }
  }

  // Footer
  &-footer {
    padding-top: 30px !important;
    padding-bottom: 25px !important;

    &.start {
      @include justify-content(flex-start !important);
    }
    &.center {
      @include justify-content(center !important);
    }

    &.u-pt--0 {
      padding-top: 0px !important;
    }
  }

  // Backdrop
  &-backdrop {
    &.show {
      @include opacity(0.75);
    }
  }

  &-section {
    &--title {
      @extend .ls05;
      color: $gray-1000;
      @include font-size(16);
      @include margin-top(35);
      font-weight: $weightBold;
      @include padding-bottom(5);
      @include margin-bottom(20);
      border-bottom: 1px solid $border;

      &:first-child {
        @include margin-top(0);
      }
    }
  }
}

/* Modal Slick Slider Arrow(s) */

$slick-arrow-width: 42px;

.Modal--Slider {
  height: 350px;
  overflow: hidden;
  max-height: 350px;

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    height: 350px;
    max-height: 350px;

    img {
      width: 100%;
      height: 350px;
      max-height: 350px;
      object-fit: cover;
      object-position: center;
    }
  }

  .slick-prev,
  .slick-next {
    z-index: 2;
    @include border-radius(50%);
    @include size($slick-arrow-width);
    @include transition(all 0.3s ease);
    @include size(40px);
    @include border-radius(50%);
    @include align-items(center);
    @include justify-content(center);
    @include transition(all 0.5s ease);
    display: flex !important;
    border: solid 1px rgba($black, 0.8) !important;
    background-color: rgba($black, 0.8) !important;
    @include box-shadow(0 4px 4px 0 rgba($black, 0.25));

    &:before {
      font-size: 0;
      color: $black;
      content: " ";
      direction: ltr;
      display: block;
      word-wrap: normal;
      white-space: nowrap;
      @include opacity(1);
      @include size(18px);
      @include transition(all 0.5s ease);
      -moz-osx-font-smoothing: grayscale;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' fill='%23fff'%3E%3Cpath d='M79.093 0L48.907 30.187 146.72 128l-97.813 97.813L79.093 256l128-128z'/%3E%3C/svg%3E");
      background-size: 18px auto;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover {
      border: solid 1px $hoverColor !important;
      background-color: $hoverColor !important;
      @include box-shadow(0 4px 4px 0 rgba($hoverColor, 0.25));

      &::before {
        color: $black;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' fill='%23000'%3E%3Cpath d='M79.093 0L48.907 30.187 146.72 128l-97.813 97.813L79.093 256l128-128z'/%3E%3C/svg%3E");
      }
    }
  }

  .slick-prev {
    left: 15px;

    &:before {
      content: "\";
    }
  }
  .slick-next {
    right: 15px;

    &:before {
      content: "\";
    }
  }
  .slick-prev {
    &::before {
      @include transform(rotate(180deg));
    }
  }
}
