@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

@font-face {
    font-family: 'GothamBook';
    src: url('../../fonts/gotham/GothamBook.eot');
    src: url('../../fonts/gotham/GothamBook.eot') format('embedded-opentype'),
         url('../../fonts/gotham/GothamBook.woff2') format('woff2'),
         url('../../fonts/gotham/GothamBook.woff') format('woff'),
         url('../../fonts/gotham/GothamBook.ttf') format('truetype'),
         url('../../fonts/gotham/GothamBook.svg#GothamBook') format('svg');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: 'GothamBold700';
    src: url('../../fonts/gotham/GothamBold700.eot');
    src: url('../../fonts/gotham/GothamBold700.eot') format('embedded-opentype'),
         url('../../fonts/gotham/GothamBold700.woff2') format('woff2'),
         url('../../fonts/gotham/GothamBold700.woff') format('woff'),
         url('../../fonts/gotham/GothamBold700.ttf') format('truetype'),
         url('../../fonts/gotham/GothamBold700.svg#GothamBold700') format('svg');
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}

/* End fontawesome fonts face files
======================== */

.if-brand {
  font-family: "FontAwesome";
  font-weight: normal;
}

.if-regular {
  font-family: "FontAwesome";
  font-weight: 400;
}

.if-solid {
  font-family: "FontAwesome";
  font-weight: 900;
}

$iconfont: "Font Awesome 5 Free";
$iconfont2: "FontAwesome";
$feathers: feathers;

// Font Sizing
// ================================
$baseFontSize: 1rem;
$uppercase: uppercase;

// Styles
// ================================

/* Primary Fonts Style */
$primaryFont: "GothamBook", sans-serif !default;
$primaryFontBold: "GothamBold700", sans-serif !default;
$secondaryFont: "Montserrat", sans-serif !default;
$tertiaryFont: "Montserrat", sans-serif !default;
$Montserrat: "Montserrat", sans-serif !default;
$weightLight: 300 !default;
$weightRegular: 400 !default;
$weightMedium: 500 !default;
$weightSemibold: 600 !default;
$weightBold: 700 !default;
$weightUltabold: 800 !default;
$weightUltrabold: 800 !default;

strong,
.strong {
  font-weight: $weightBold;
}

em,
.em {
  font-style: italic;
}

// Text Decoration
// ================================
.txt-dec-none {
  text-decoration: none;
}

// Defaults
// ================================
html,
body {
  font-family: $primaryFont;
  font-size: $baseFontSize;
  color: $greyish;
}

// Headings
// ================================
h1,
h2,
h3 {
  font-family: $primaryFont;
}

h4,
h5,
h6 {
  font-family: $primaryFont;
}

.form-section h2 {
  font-family: $primaryFont;
}
.text-sec {
    font-family: $secondaryFont;
}
.text-primary {
    font-family: $primaryFont;
}

.header-heading {
  margin-bottom: 15px;
}

.title {
  color: $black;
  @extend .fs--20;
  font-weight: $weightBold;
  @include margin-bottom(10);
}

// Links
// ================================
a,
a:link,
a:visited,
a:active {
  text-decoration: none;
}

/* Typography
------------------------------------*/
h1 {
  line-height: 1.6;
}

h2 {
  line-height: 1.5;
}

h3 {
  line-height: 1.4;
}

h4 {
  line-height: 1.3;
}

h5 {
  line-height: 1.2;
}

h6 {
  line-height: 1.2;
}

p {
  @include font-size(16);
  margin-bottom: 15px;
  line-height: 1.5;

  &:only-child,
  &:last-child {
    margin-bottom: 0px;
  }
}

ul,
ol {
  position: relative;

  li {
    list-style: none;

    a {
      &:hover {
        text-decoration: none;
        @include transition(all 0.3s ease-in);
      }
    }
  }
}

ol {
  li {
    list-style-position: outside;
  }
}

.marker-list li {
  list-style: square;
  list-style-position: inside;
}

iframe {
  border: none;
}

q {
  @include font-size(18);
  font-style: italic;
  color: $black;
}

//=My Letterspacing variables
//Usually kept in _variables.scss
.ls-20 {
  letter-spacing: -2px;
}
.ls-15 {
  letter-spacing: -1.5px;
}
.ls-10 {
  letter-spacing: -1px;
}
.ls-01 {
  letter-spacing: -0.01px;
}
.ls-1 {
  letter-spacing: -0.1px;
}
.ls-05 {
  letter-spacing: -0.5px;
}
.ls-025 {
  letter-spacing: -0.25px;
}
.ls0 {
  letter-spacing: 0px;
}
.ls015 {
  letter-spacing: 0.15px;
}

.ls025 {
  letter-spacing: 0.25px;
}
.ls-015 {
  letter-spacing: -0.075px;
}
.ls05 {
  letter-spacing: 0.5px;
}

.ls04 {
  letter-spacing: 0.4px;
}

.ls15 {
  letter-spacing: 1.5px;
}

.ls25 {
  letter-spacing: 2.5px;
}

.ls1 {
  letter-spacing: 1px;
}

.text--green {
  color: $green !important;
}
.text--warning {
  color: $warning_sh2 !important;
}
.text--error {
  color: $error !important;
}
.text--primary {
  color: $primaryColor !important;
}
.text--dark-gray {
  color: $dark-gray !important;
}

.weightMedium {
  font-weight: $weightMedium !important;
}

//=My Font Sizes variables
//Usually kept in _variables.scss
.fs {
  &--10 {
    @include font-size(10);
    line-height: 1.35;
  }
  &--11 {
    @include font-size(11);
    line-height: 1.35;
  }
  &--12 {
    @include font-size(12);
    line-height: 1.35;
  }
  &--13 {
    @include font-size(13);
    line-height: 1.35;
  }
  &--14 {
    @include font-size(14);
    line-height: 1.35;
  }
  &--15 {
    @include font-size(15);
    line-height: 1.35;
  }
  &--16 {
    @include font-size(16);
    line-height: 1.35;
  }
  &--17 {
    @include font-size(17);
    line-height: 1.35;
  }
  &--18 {
    @include font-size(18);
    line-height: 1.35;
  }
  &--19 {
    @include font-size(19);
    line-height: 1.35;
  }
  &--20 {
    @include font-size(17);
    line-height: 1.35;

    @include responsive(sm) {
      font-size: 1.125rem;

      @include respond-to(md) {
        font-size: 1.188rem;

        @include respond-to(md) {
          font-size: 1.25rem;
        }
      }
    }
  }
}

/* Material Icons Size(s) */
.material-icons {
  &.icons {
    @include margin-right(5);

    &.MuiIcon-fontSizeSmall {
      @include font-size(16);
      line-height: 1;
    }

    &.MuiIcon-fontSizeLarge {
      @include font-size(26);
      line-height: 1;
    }
  }
}

.MuiSvgIcon {
  &-root {
    @include margin-top(-1);
  }

  &-fontSizeSmall {
    font-size: 16px !important;
    @include size(18px !important);
  }

  &-fontSizeLarge {
    font-size: 26px !important;
    @include size(26px !important);
  }
}
