/* Begin FAQs Page style(s) */

.faq--page {
  .faq {
    $faqSelf: &;

    &-body {
      width: 100%;
    }
    &-row-wrapper {
      width: 100%;
    }

    &-row {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      border-bottom: 1px solid $border !important;
    }
  }

  .row-title {
    @extend .animation-all;
    padding-top: 15px !important;
    padding-bottom: 15px !important;

    &-text {
      color: $black;
      font-size: 1.125rem;
      font-weight: $weightSemibold;
    }
    .icon-wrapper {
      width: 20px !important;
      height: 20px !important;
      max-width: 20px !important;
      max-height: 20px !important;

      svg {
        fill: none !important;
        stroke: $blue_sh2 !important;
      }
    }

    &[aria-expanded="true"] {
      background-color: $white;
    }
  }

  .primary--copy {
    color: $black;
    font-size: 16px;
    line-height: 1.5;
    @include margin-bottom(20);

    &:last-child {
      @include margin-bottom(10);
    }
  }

  .row-content {
    &-text {
      padding-top: 20px !important;
      padding-bottom: 15px !important;
    }

    p {
      @extend .primary--copy;
    }
    ul {
      li {
        @extend .primary--copy;
        padding-left: 1.75em;

        &::before {
          left: 3px;
          content: "";
          color: $blue_sh2;
          border-radius: 1px;
          position: absolute;
          @include size(10px);
          display: inline-block;
          vertical-align: middle;
          @include margin-top(2);
          @include transform(rotate(45deg));
          background-color: darken($hoverColor, 10%);
        }
      }
    }
    a {
      @extend .link-secondary;
      display: inline-block;
    }
  }
}

// Firfox fixes
@-moz-document url-prefix() {
  .row-title {
    &-text {
      @include padding-top(6);
    }
    .icon-wrapper {
      top: 1.25rem !important;
    }
  }
}

/* End FAQs Page style(s) */
