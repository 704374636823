/* Begin Custom Dropdown style(s) */
.no-maxwidth {
  max-width: 100% !important;
}
.no-border {
  border: 0 none !important;
}
.animation-all {
  @include transition(all 0.5s ease);
}
.img-cover {
  object-fit: cover;
  object-position: center;
}
.link-primary {
  color: $black;
  @extend .animation-all;

  &:hover,
  &:focus,
  &:active {
    color: $subprimary;
  }
}
.link-secondary {
  color: $subprimary;
  @extend .animation-all;

  &:hover,
  &:focus,
  &:active {
    color: $blue_sh2;
  }
}
body {
  background-color: $black !important;
}
.body-wrap {
  overflow: hidden;
  @include flexbox;
  min-height: 100vh;
  position: relative;
  background-color: $black;
  @include flex-direction(column, vertical);

  &::before {
    top: 0;
    left: 0;
    z-index: 1;
    content: "";
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    position: absolute;
    background-color: $black !important;
    display: none !important;

    @media (max-width: 767px) {
      max-height: 600px;
    }
  }
}

section {
  background-color: $white;
}

.container-fluid {
  max-width: 1350px;
  padding-left: 15px !important;
  padding-right: 15px !important;

  @include responsive(sm) {
    padding-left: 20px !important;
    padding-right: 20px !important;

    @include respond-to(md) {
      padding-left: 30px !important;
      padding-right: 30px !important;

      @include respond-to(lg) {
        padding-left: 40px !important;
        padding-right: 40px !important;
      }
    }
  }
}

.main-page {
  @include margin-top(69);

  @include breakpoint(991) {
    @include margin-top(55);
  }
}

[class^="section--"] {
  position: relative;

  &[data-bg="purple"],
  &[data-bg="white"],
  &[data-bg="gray"],
  &[data-bg="purple2"],
  &[data-bg="black"] {
    &:before {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      pointer-events: none;
    }
  }
  &[data-bg="purple"] {
    &:before {
      @include linear-gradient(to bottom, $primary, $subprimary);
    }
  }
  &[data-bg="purple2"] {
    &:before {
      @include linear-gradient(to top, $primary, $subprimary);
    }
  }

  &[data-bg="white"] {
    &:before {
      background-color: $white;
    }
  }

  &[data-bg="gray"] {
    color: $grey;
    &:before {
      background-color: $white_sh2;
    }
  }

  &[data-bg="black"] {
    color: $white;
    background-color: $gray-900;
  }
}

.bg-light-gray {
  background-color: $white_sh3;
}
.h-120px {
  @include padding(15);
  height: 170px !important;
}
textarea {
  resize: none;
}
.form-control::-webkit-input-placeholder {
  color: $grey;
  @include opacity(0.5);
  text-overflow: ellipsis;
}
.form-control::-moz-placeholder {
  color: $grey;
  @include opacity(0.5);
  text-overflow: ellipsis;
}
.form-control:-ms-input-placeholder {
  color: $grey;
  @include opacity(0.5);
  text-overflow: ellipsis;
}

.page--adjust {
  @include flexbox;
  @include padding(100 0);
  @include align-items(center);
  @include justify-content(center);

  @include responsive(sm) {
    @include padding(150 0);

    @include respond-to(md) {
      min-height: 540px;
      @include padding(200 0);
    }
  }
}

.bg-white,
.bg-light-gray,
.meet--expert {
  @include padding(40 0);

  @include responsive(sm) {
    @include padding(50 0);

    @include respond-to(md) {
      @include padding(75 0);
    }
  }
}
h6 {
  font-size: 1.8rem;
  line-height: 2.5rem;
}

.meet--expert {
  &__image {
    overflow: hidden;
    position: relative;
    border-radius: 10px;
  }

  &__heading {
    position: relative;
    @include margin-bottom(25);

    &::before {
      bottom: -2px;
      left: -150px;
      width: 250px;
      content: " ";
      height: 0.5rem;
      position: absolute;
      background-color: $black_sh6;
    }

    h4 {
      color: $grey;
      font-size: 1rem;
      text-transform: uppercase;
    }

    h1 {
      color: $black;
      font-size: 35px;
      text-transform: uppercase;
      font-weight: $weightSemibold;

      @include breakpoint(767) {
        font-size: 30px;
      }
    }
  }

  &__content {
    position: relative;
    @include padding-top(35);
    @include padding-left(0);

    @include responsive(sm) {
      @include padding-left(10);

      @include respond-to(md) {
        @include padding-top(0);
        @include padding-left(30);
      }
    }

    p {
      font-size: 16px;
      line-height: 1.5;
      color: $grey_sh1;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.featured--testimonials {
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
}

.featured--testimonials,
.features--properties {
  @include padding(45 0);

  @include responsive(sm) {
    @include padding(55 0);

    @include respond-to(md) {
      @include padding(70 0);
    }
  }

  &-wrap {
    @include flexbox;
    width: calc(100% + 30px);
    @include margin-left(-15);
    @include margin-right(-15);
    @include flex-wrap(wrap);
    @include margin-bottom(30);
    @include justify-content(center);
  }
}

.features--properties {
  &-item {
    max-width: 33.3333%;
    @include flex-basis(33.3333%);
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
    @include margin-bottom(30);

    @include breakpoint(480) {
      max-width: 100%;
      @include flex-basis(100%);
    }

    @media screen and (min-width: 481px) and (max-width: 700px) {
      max-width: 50%;
      @include flex-basis(50%);
    }

    @media screen and (min-width: 701px) and (max-width: 991px) {
      max-width: 33.3333%;
      @include flex-basis(33.3333%);
    }

    &-inner {
      box-shadow: $box-shadow;
      border: 1px solid $border;
    }

    &-image {
      overflow: hidden;
      position: relative;
      padding-bottom: 65%;

      > span {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;

        img {
          width: 100%;
          height: 100%;
          @extend .img-cover;
        }
      }
    }

    &-content {
      font-size: 1.125rem;
      @include padding(30 20 25);
      border-top: 1px solid $border;

      .price {
        color: $black;
        font-size: 1rem;
        font-weight: $weightBold;
        @include margin-bottom(15);
      }
      .description,
      .specs {
        color: $grey;
        @include font-size(15);
        line-height: 1.5;
        @include margin-bottom(10);
        position: relative;
      }
      .description {
        min-height: 40px;
        max-height: 40px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .specs {
        @include margin-bottom(0);
        font-weight: $weightSemibold;
      }
    }
  }
}

.w--100 {
  width: 100% !important;
  max-width: 100% !important;
  @include flex-basis(100% !important);
  @include margin-bottom(35);

  @include breakpoint(991) {
    max-width: 100%;
    @include flex-basis(100%);
  }
}

// .lazy-load-image-background {
//   &::before {
//     content: " ";
//     position: absolute;
//     @include size(24px);
//     top: calc(50% - 12px);
//     left: calc(50% - 12px);
//     @extend .img-preloader;
//     @extend .animation-all;
//   }
// }

.blur {
  -webkit-filter: blur(20px);
  filter: blur(20px);
  transition: filter 600ms, -webkit-filter 600ms;

  &.lazy-load-image-loaded {
    -webkit-filter: blur(0);
    filter: blur(0);

    // &::before {
    //   visibility: hidden;
    //   @include opacity(0);
    // }
  }
}

.section-header {
  @include margin-bottom(20);

  h2 {
    z-index: 2;
    color: $black;
    position: relative;
    @include font-size(22);
    line-height: 1.5;
    text-transform: $uppercase;
    font-weight: $weightRegular;

    &::before {
      top: 0;
      left: 50%;
      z-index: -1;
      width: 200px;
      height: 30px;
      content: " ";
      max-width: 200px;
      position: absolute;
      pointer-events: none;
      @include opacity(0.5);
      @include transform(translateX(-50%));
      background-image: url("../../../images/pattern.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 200px auto;
      display: none !important;
    }
  }
}

.find--home {
  width: 100%;
  max-width: 650px;
  position: relative;

  .form-group {
    .form-control {
      height: 70px;
      border: 0 none;
      font-size: 20px;
      min-height: inherit;
      max-height: inherit;
      border-radius: 0.5rem;
      @include padding(10 15);
      background-color: $white;
      @include padding-left(50);
      @include padding-right(223);
      font-family: $secondaryFont;
    }
  }
  .find--icon {
    top: 50%;
    left: 20px;
    position: absolute;
    @include transform(translateY(-50%));
  }
  .find--button {
    top: 10px;
    right: 10px;
    height: 50px;
    color: $black;
    font-size: 20px;
    line-height: 35px;
    position: absolute;
    border-radius: 0.5rem;
    @include padding(15 25);
    font-weight: $weightBold;
    background-color: $yellow;

    &:hover,
    &:focus,
    &:active {
      color: $yellow;
      background-color: $black;
    }
  }
}

.react-parallax {
  z-index: 1;
  background-size: cover !important;
  background-attachment: fixed !important;
  transition-duration: 0s;
  -moz-transition-duration: 0s;
  -webkit-transition-duration: 0s;
  -o-transition-duration: 0s;

  .container {
    @include padding-top(120);
    @include padding-bottom(120);

    @include breakpoint(700) {
      @include padding-top(100);
      @include padding-bottom(100);
    }
  }

  &-bgimage {
    object-fit: cover;
    object-position: center;
  }
  .bg-extra-dark-gray {
    background-color: $grey_sh1;
  }
  .opacity-extra-medium {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    @include opacity(0.5);
  }
  .page-title-extra-small {
    h1 {
      @include font-size(16);
      line-height: 20px;
      font-weight: $weightMedium;
      @include margin-bottom(20);
    }
    h2 {
      @include font-size(30);
      @include margin-bottom(0);
      font-weight: $weightMedium;

      @include breakpoint(700) {
        @include font-size(30);
      }
    }
  }
}

.opacity-6 {
  @include opacity(0.6);
}

.border-no {
  border: 0 none !important;
}
.border-all {
  border: 1px solid !important;
}
.border-color-medium-gray {
  border-color: $border !important;
}
.bg-white {
  background-color: $white;
}
.border-radius-0 {
  border-radius: 0 !important;
}
.border-radius-2px {
  border-radius: 0.125rem;
}
.border-radius-4px {
  border-radius: 0.25rem;
}
.padding-15px-all {
  @include padding(25 15);
}
.font-size-16 {
  font-size: 16px;
  line-height: 1.4;
}
.font-size-18 {
  font-size: 18px;
  line-height: 1.2;
}
.font-size-22 {
  font-size: 22px;
  line-height: 1.2;
}
.font-size-32 {
  font-size: 32px;
  line-height: 1.2;

  @include breakpoint(767) {
    font-size: 28px;
  }
}
.size-100 {
  color: $grey;
  @include size(60px);
  > span {
    color: $grey;
    @include size(60px);

    svg {
      color: $grey;
    }
  }

  @include responsive(sm) {
    @include size(60px);
    > span {
      @include size(60px);
    }

    @include respond-to(md) {
      @include size(80px);
      > span {
        @include size(80px);
      }

      @include respond-to(lg) {
        @include size(100px);
        > span {
          @include size(100px);
        }
      }
    }
  }
}
.fs-100 {
  color: $grey;
  font-size: 60px;
  @include size(60px);

  @include responsive(sm) {
    font-size: 60px;
    @include size(60px);

    @include respond-to(md) {
      font-size: 80px;
      @include size(80px);

      @include respond-to(lg) {
        font-size: 100px;
        @include size(100px);
      }
    }
  }
}
.font-weight-500,
.font-weight-600,
.font-weight-700 {
  font-weight: 500;
}
.font-weight-800 {
  font-weight: $weightBold;
}
.text-gray {
  color: $grey;
}
.text-extra-dark-gray {
  color: $grey_sh1;
}
.w-60px {
  width: 60px;
}
.img-circle {
  border-radius: 50%;
}
.text-medium {
  font-size: 14px;
  line-height: 20px;
}
.line-height-18px {
  line-height: 18px;
}
p {
  margin: 0 0 25px;
}
.text-extra-light-gray {
  color: #b7b7b7;
}
.text-upper {
  text-transform: uppercase;
}
/* Links */
.btn-link.text-fast-blue,
.text-fast-blue,
a.text-fast-blue-hover:hover {
  color: #49a7d9;
}

.medium-icon {
  @include flexbox;
  li {
    margin: 0 8px;
  }
}
.medium-icon a {
  width: 50px;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
}

.social-icon-style-09 {
  a {
    color: $grey_sh1;
    text-align: center;
    border-radius: 100%;
    position: relative;
    text-align: center;
    display: inline-block;

    &::after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: " ";
      display: block;
      position: absolute;
      border: 1px solid;
      border-radius: 100%;
      @include opacity(0);
      @include transform(scale(0.8));
      -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
      transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    }

    &:hover {
      color: $white;

      &::after {
        @include opacity(0.4);
        @include transform(scale(1.3));
      }
    }

    &.facebook {
      &:hover {
        background-color: $facebook;
      }
      &::after {
        color: $facebook;
        border-color: $facebook;
      }
    }

    &.twitter {
      &:hover {
        background-color: $twitter;
      }
      &::after {
        color: $twitter;
        border-color: $twitter;
      }
    }

    &.linkedin {
      &:hover {
        background-color: $linkedin;
      }
      &::after {
        color: $linkedin;
        border-color: $linkedin;
      }
    }

    &.instagram {
      &:hover {
        background-color: $instagram;
      }
      &::after {
        color: $instagram;
        border-color: $instagram;
      }
    }
  }
}

.gallery--section {
  > .swiper-container {
    overflow: hidden;
    max-height: 700px;
  }
}

.gallery--section {
  width: 100%;
  overflow: hidden;
  max-height: 700px;
  position: relative;

  &__content {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: $white;
    @include flexbox;
    position: absolute;
    @include padding-bottom(100);
    @include align-items(flex-start);
    @include justify-content(flex-end);
    background-color: rgba($black, 0.33);
    @include flex-direction(column, vertical);

    .gallery--title,
    .gallery--name,
    .gallery--excerpt {
      letter-spacing: 3px;
      text-transform: uppercase;
    }

    .gallery--title {
      font-size: 12px;
      @include margin-bottom(20);
      font-weight: $weightSemibold;
      -webkit-animation: 0.4s ease-in-out 0s normal none 1 running fadeInDown;
      animation: 0.5s ease-in-out 0s normal none 1 running fadeInDown;
    }
    .gallery--name {
      font-size: 40px;
      line-height: 40px;
      @include margin-bottom(5);
      font-family: $primaryFont !important;
      -webkit-animation: 0.8s ease-in-out 0s normal none 1 running fadeInDown;
      animation: 0.8s ease-in-out 0s normal none 1 running fadeInDown;
    }
    .gallery--excerpt {
      font-size: 13px;
      letter-spacing: 2px;
      text-transform: none;
      @include margin-bottom(0);
      -webkit-animation: 1.2s ease-in-out 0s normal none 1 running fadeInDown;
      animation: 1.2s ease-in-out 0s normal none 1 running fadeInDown;
    }

    .img-fluid {
      @extend .img-cover;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: auto;
    left: auto;
    bottom: 80px;
    @include flexbox;
    position: absolute;
    @include size(40px);
    @include border-radius(3px);
    @include align-items(center);
    @include justify-content(center);
    @extend .animation-all;
    background-color: rgba($black, 0.8);

    &::after {
      color: $white;
      display: block;
      font-size: 20px;
      text-align: center;
      @include size(20px);
      @extend .animation-all;
    }

    &:hover {
      color: $black;
      background-color: $hoverColor;

      &::after {
        color: $black;
      }
    }
  }
  .swiper-button-next {
    right: 30px;
  }
  .swiper-button-prev {
    right: 80px;
  }

  .swiper-slide-active {
    .gallery--section__content {
      .gallery--title {
        -webkit-animation: 0.4s ease-in-out 0s normal none 1 running fadeInDown;
        animation: 0.5s ease-in-out 0s normal none 1 running fadeInDown;
      }
      .gallery--name {
        -webkit-animation: 0.8s ease-in-out 0s normal none 1 running fadeInDown;
        animation: 0.8s ease-in-out 0s normal none 1 running fadeInDown;
      }
      .gallery--excerpt {
        -webkit-animation: 1.2s ease-in-out 0s normal none 1 running fadeInDown;
        animation: 1.2s ease-in-out 0s normal none 1 running fadeInDown;
      }
    }
  }
}

@media (max-width: 767px) {
  .gallery--section {
    height: 400px;
    max-height: 400px;
    > .swiper-container {
      height: 400px;
      max-height: 400px;
    }

    &__content {
      @include padding-bottom(120);

      .gallery--name {
        font-size: 28px;
        line-height: 38px;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      bottom: 40px;
    }

    .img-fluid {
      height: 400px;
      max-height: 400px;
      object-fit: cover;
      object-position: center;
    }
  }
}

.h-1px {
  height: 1px;
}
.margin-25px-tb {
  @include margin-top(15);
  @include margin-bottom(15);
}
.bg-medium-gray {
  background-color: $offwhite;
}
.margin-15px-right {
  @include margin-right(15);
}

.featured--testimonials {
  &-wrap {
    @include margin-top(45);
  }

  &-item {
    max-width: 33.3333%;
    @include flex-basis(33.3333%);
    padding-left: 15px;
    padding-right: 15px;
    @include margin-bottom(50);

    @include breakpoint(480) {
      max-width: 100%;
      @include flex-basis(100%);
    }

    @media screen and (min-width: 481px) and (max-width: 700px) {
      max-width: 50%;
      @include flex-basis(50%);
    }

    &-inner {
      @include padding(25);
      @include padding-top(55);
      @include border-radius(3px);
      background-color: $white_sh1;
      @extend .animation-all;

      &:hover {
        background-color: $white;
        box-shadow: $box-shadow;
      }
    }

    .testimonials-bubble {
      position: relative;
      width: calc(100% - 0);
      max-width: calc(100% - 0);
      @include padding-left(25);

      &::before,
      &::after {
        content: " ";
        position: absolute;
        @include size(15px);
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 191.029 191.029'%3E%3Cpath d='M44.33 88.474v15.377h38.417v82.745H0v-82.745h.002V88.474c0-31.225 8.984-54.411 26.704-68.918C38.964 9.521 54.48 4.433 72.824 4.433v44.326c-9.958 0-28.494 0-28.494 39.715zm136.777-39.715V4.433c-18.343 0-33.859 5.088-46.117 15.123-17.72 14.507-26.705 37.694-26.705 68.918v98.122h82.744v-82.745h-38.417V88.474c.001-39.715 18.537-39.715 28.495-39.715z' fill='%23AAA'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 15px auto;
      }

      &::before {
        left: 0;
        top: -4px;
      }
      &::after {
        right: 10px;
        bottom: 6px;
        @include transform(rotate(180deg));
      }
    }
  }
  .author {
    @include flexbox;
    @include padding-top(10);
    @include align-items(center);
    @include justify-content(flex-start);

    .d-inline-flex {
      @include flex-wrap(wrap);
      span {
        width: 100%;
        display: block;
        text-align: right;
        font-family: $primaryFontBold;

        &:first-child {
          color: $black;
          font-size: 15px;
          font-family: $primaryFontBold;
        }
        // &:last-child {
        //   font-size: 13px;
        //   @include opacity(0.75);
        // }
      }
    }
  }
}

.featured--testimonials-item {
  &:nth-child(2n) {
    .featured--testimonials-item-inner {
      background-color: $white;
    }
  }
  .link-secondary {
    @extend .animation-all;
    @extend .u-inline-flexed;
    display: inline-block;
    font-family: $secondaryFont;
  }
  .content-css {
    width: 100%;
    position: relative;
    // @extend .u-flex-wrap;
    @extend .u-inline-flexed;
    @extend .u-flex-direction-column;
    @extend .animation-all;
    color: $black;
    line-height: 1.65;

    // > span {
    //   width: 100% !important;
    //   display: table !important;

    //   > span {
    //     width: 100% !important;
    //     display: table !important;
    //   }
    // }

    br {
      display: none;
    }
  }

  .author {
    .d-inline-flex {
      span {
        color: $black;
      }
    }
  }
}

.feature--box {
  width: 100%;
  z-index: 1;
  height: 100%;
  @include flexbox;
  position: relative;
  text-align: center;
  background-color: $white;
  @include border-radius(3px);
  @include align-items(center);
  @include justify-content(center);
  @extend .animation-all;
  @include flex-direction(column, vertical);
  @include box-shadow(0 0 30px rgba($black, 0.1));

  &__inner {
    height: 100%;
    overflow: hidden;
    @include padding(50 35);
  }

  &__icon {
    display: block;
    @include size(60px);
    margin: 0px auto 2rem;
  }

  &__content {
    h4 {
      font-size: 16px;
      font-weight: $weightSemibold;
    }
  }
}

.bg-patten {
  background-image: url("../../../images//pattern-05.png"),
    url("../../../images//pattern-06.png");
  background-position: left bottom, right top;
  background-repeat: no-repeat, no-repeat;
  background-size: auto, auto;

  p {
    color: $black;
    font-size: 15px;
    line-height: 1.5;
  }
  ul {
    li {
      color: $black;
      font-size: 15px;
      line-height: 1.5;
      margin-bottom: 15px;
    }
  }
}
.bg-gray-01 {
  background-color: #f8f8f8 !important;
}
.pb-13,
.py-13 {
  padding-bottom: 4.25rem !important;
}
.mt-n13,
.my-n13 {
  margin-top: -4.25rem !important;
}
.z-index-3 {
  z-index: 3 !important;
}
.border-0 {
  border: none !important;
}
.card {
  transition: all 0.2s;
}
.mb-12,
.my-12 {
  margin-bottom: 5.625rem !important;
}
.mxw-751 {
  margin: 0 auto;
  max-width: 751px;
  margin-bottom: 0px;
}
.mt-8,
.my-8 {
  margin-top: 3.125rem !important;
}
.shadow-0 {
  box-shadow: none !important;
}
.shadow-2 {
  box-shadow: 0 20px 15px 0 rgba(51, 51, 51, 0.1) !important;
}
.shadow-3 {
  box-shadow: 0 5px 20px 2px rgba(51, 51, 51, 0.1) !important;
}
.p-7 {
  padding: 2.5rem !important;
}
.card {
  &.pt-5 {
    padding-top: 2.5rem !important;
  }
}
.card-img,
.card-img-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.card-img-icon {
  @include flexbox;
  align-items: center;
  justify-content: center;
  @include size(80px);

  > span {
    @include size(80px);
  }
  img,
  .img-fluid {
    @extend .img-cover;
    @include size(80px);
  }
}
.card-text {
  color: $greyish;
}
.card-title {
  color: $black;
  font-size: 20px;
}
.fs-20 {
  color: $black;
  font-size: 20px;
}
.fs-22 {
  color: $black;
  font-size: 22px;
}

.sub--title {
  &.with-border-bottom {
    border-bottom: 1px solid $border;
  }
  h4 {
    font-weight: $weightBold;
  }
}

.sharethis__share-button {
  cursor: pointer;
  @extend .animation-all;
  @extend .border-radius-4px;

  &:hover:not(:active) {
    @include opacity(0.75);
  }

  svg {
    @extend .border-radius-4px;
  }
}

.property--specs {
  @extend .u-flexed;
  min-height: 50px;
  @include padding(0 10);
  background: $white_sh4;
  @include margin(0 0 15 0);
  @include border-radius(3px);

  &__label,
  &__excerpt {
    font-size: 16px;
    @extend .text-sec;
    line-height: 50px;
  }

  &__label {
    color: $black;
    // max-width: 40%;
    // @include flex(0 0 40%);
    margin-bottom: 0;
    @include padding-left(5);
    @include padding-right(15);
    font-weight: $weightMedium;
  }

  &__excerpt {
    color: $grey_sh3;
    // max-width: 60%;
    // @include flex(0 0 60%);
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.card {
  &__details {
    h3 {
      color: $black;
      font-size: 22px;
      @extend .font-weight-700;
    }
    p {
      color: $black;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.properties--view {
  @extend .u-flexed;

  &__mode {
    @extend .u-flexed;
  }
  li {
    @include margin-left(15);

    > a {
      color: $black;
      font-size: 15px;
      @extend .u-flexed;
      @extend .u-align-center;
      font-family: $secondaryFont;
      font-weight: $weightSemibold;
    }
  }
}
.properties--sold-lists,
.properties--sold {
  .features--properties {
    &-item {
      max-width: 100%;
      @include padding(0);
      @include flex-basis(100%);
      @include margin-bottom(30);

      @include breakpoint(480) {
        max-width: 100%;
        @include flex-basis(100%);
      }

      @media screen and (min-width: 481px) and (max-width: 700px) {
        max-width: 100%;
        @include flex-basis(100%);
      }

      @media screen and (min-width: 701px) and (max-width: 991px) {
        max-width: 100%;
        @include flex-basis(100%);
      }

      &-image {
        display: block;
      }

      &-content {
        text-align: center;
        @extend .no-border;
        position: relative;
        @include padding(20);
        @include padding-bottom(0);

        .name {
          display: block;
          font-size: 1rem;
          color: $subprimary;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          @include margin-bottom(10);
          font-weight: $weightMedium;
          @extend .animation-all;

          &:hover,
          &:focus,
          &:active {
            color: $blue_sh2;
          }
        }
        .description,
        .specs {
          color: $grey;
          @include font-size(15);
          line-height: 1.5;
          @include margin-bottom(10);
        }
        .specs {
          @include margin-bottom(0);
          font-weight: $weightSemibold;
        }
      }
    }

    &__price {
      margin: -1px;
      color: $white;
      width: calc(100% + 2px);
      font-size: 18px;
      min-height: 24px;
      text-align: center;
      @include padding(6 10);
      font-weight: $weightBold;
      font-family: $secondaryFont;
      background-color: $white_sh4;
      border: 1px solid rgba($black, 0.15);
      text-shadow: 0 1px 0 rgba($black, 0.1);
    }
  }
  .list-fx-features {
    width: 100%;
    @extend .u-flexed;
    position: relative;
    @extend .u-flex-wrap;
    @extend .u-align-center;
    @extend .u-justify-center;
    @include padding(0 10 10);

    &.start {
      @extend .u-justify-start;
    }

    &-info {
      width: auto;
      flex: inherit;
      color: $black;
      font-size: 14px;
      @extend .u-inline-flexed;
      @extend .u-align-center;
      @include margin-right(20);
      font-family: $secondaryFont;

      &:last-child,
      &:only-child {
        @include margin-right(0);
      }

      &-icon {
        @include size(22px);
        border-radius: 50%;
        @include margin-right(8);
        @extend .u-inline-flexed;
        @extend .u-align-center;
        @extend .u-justify-center;
        background-color: $white_sh4;
      }
    }
  }

  .button-loading {
    position: relative;

    &:after {
      top: auto;
      left: auto;
      content: " ";
      position: relative;
      @include size(16px);
      @include opacity(0);
      display: inline-block;
      vertical-align: middle;
      @include margin-left(10);
      border: 1px solid $yellow;
      border-left-color: $black;
      @include border-radius(50%);
      @include transition(opacity 0.2s ease);
      background-color: transparent !important;
    }
    // &:after {
    //   @include opacity(1);
    //   animation: load-spin 450ms infinite linear;
    //   -webkit-animation: load-spin 450ms infinite linear;
    // }
  }

  .photos--count {
    right: 0;
    top: -26px;
    z-index: 3;
    height: 26px;
    color: $white;
    cursor: pointer;
    font-size: 13px;
    @extend .u-flexed;
    position: absolute;
    @include padding(3 10);
    @extend .u-align-center;
    @extend .u-justify-center;
    font-family: $secondaryFont;
    border-top-left-radius: 3px;
    background-color: rgba($black, 0.5);
  }

  .fs-20 {
    color: $black;
    line-height: 1.5;
    font-size: 1.25rem;
    font-weight: $weightBold;
  }
}

body.sold-properties {
  .properties--sold-lists {
    .button-loading {
      &:after {
        display: none;
      }
    }

    > .properties--sold {
      .button-loading {
        &:after {
          display: block;
        }
      }
    } 
  }
}

.properties--sold {
  .button-loading {
    position: relative;

    &:after {
      @include opacity(1);
      animation: load-spin 450ms infinite linear;
      -webkit-animation: load-spin 450ms infinite linear;
    }
  }
}

.result--sorting {
  .dropdown {
    .btn {
      height: auto;
      padding: 5px 10px;
      text-transform: capitalize;
      border-color: darken($grey_sh1, 10%) !important;
      background-color: darken($grey_sh1, 10%) !important;
    }
    &-menu {
      margin: 0 !important;
    }
    &-item {
      color: $black;
      font-size: 14px;
      @include padding(6 15);
      font-family: $secondaryFont;
      @include transition(all 0.2s ease);
    }
  }
}

.lead--btns {
  width: calc(100% + 10px);
  @extend .u-flexed;
  @extend .u-justify-btw;
  @extend .u-align-center;
  @include margin-left(-5);
  @include margin-right(-5);

  &__items {
    width: 33.3333%;
    @include padding(0 5);
  }
  a,
  .u-button-pills {
    width: 100%;
    color: $black;
    font-size: 14px;
    @extend .u-flexed;
    @extend .animation-all;
    @include padding(5 20);
    @extend .u-align-center;
    @extend .u-justify-center;
    @extend .border-radius-4px;
    background-color: $white_sh4;
    border: 1px solid darken($white_sh4, 5%);

    &:hover {
      color: $white;
      background-color: darken($grey_sh1, 5%);
      border: 1px solid darken($grey_sh1, 10%);
    }
  }
}

.properties--details {
  width: 100%;
  @include padding-top(30);

  .nav-tabs {
    margin-bottom: 0 !important;
    border-bottom: 1px solid $border;

    > li {
      margin-right: 15px;
      margin-bottom: -1px !important;

      > a {
        color: $white;
        display: block;
        min-width: 80px;
        cursor: pointer;
        text-align: center;
        font-size: 1.125rem;
        border-color: $grey_sh3;
        @include padding(10 20);
        @extend .border-radius-0;
        background-color: $grey_sh3;
        margin-bottom: 0 !important;
        @extend .animation-all;
        @extend .text-sec;

        &:hover,
        &:focus,
        &:active {
          color: $white;
          border-color: darken($grey_sh3, 10%);
          background-color: darken($grey_sh3, 10%);
        }

        &.active {
          color: $black;
          border-color: $border;
          background-color: $white;
          border-bottom-color: $white;

          &:hover,
          &:focus,
          &:active {
            color: $black;
            border-color: $border;
            background-color: $white;
            border-bottom-color: $white;
          }
        }
      }
    }
  }
  .tab-content {
    background-color: $white;
    border: 1px solid $border;
    border-top: 0 none !important;

    .tab-pane {
      @include padding(30 20 25);
    }
  }

  &__gallery {
    .Modal--Slider {
      height: 450px;
      overflow: hidden;
      max-height: 450px;
      @extend .shadow-3;
      border: 1px solid $border;

      .slick-slider,
      .slick-list,
      .slick-track,
      .slick-slide {
        height: 450px;
        max-height: 450px;

        img {
          width: 100%;
          height: 450px;
          max-height: 450px;
          @extend .img-cover;
        }
      }
    }
  }
}

.properties--gallery {
  width: 100%;
  @extend .shadow-3;
  @include padding(10);
  border: 1px solid $border;

  .slick-slider {
    &.gallery--slider {
      height: 400px;
      max-height: 400px;
      @include margin-bottom(10);

      .slick-list,
      .slick-track,
      .slick-slide {
        height: 400px;
        max-height: 400px;

        img {
          width: 100%;
          height: 400px;
          max-height: 400px;
          @extend .img-cover;
        }
      }

      @include breakpoint(767) {
        height: 250px;
        max-height: 250px;

        .slick-list,
        .slick-track,
        .slick-slide {
          height: 250px;
          max-height: 250px;

          img {
            width: 100%;
            height: 250px;
            max-height: 250px;
          }
        }
      }

      &__thumbs {
        height: 100px;
        max-height: 100px;
        // @include margin(0 -5);
        // width: calc(100% + 10px);

        .slick-list,
        .slick-track,
        .slick-slide {
          height: 100px;
          max-height: 100px;

          img {
            width: 100%;
            height: 100px;
            max-height: 100px;
            @extend .img-cover;
          }
        }
        .slick-slide {
          @include margin-left(5);
          @include margin-right(5);
          @include opacity(0.5);

          &.slick-current {
            @include opacity(1);
          }
        }

        @include breakpoint(767) {
          height: 60px;
          max-height: 60px;

          .slick-list,
          .slick-track,
          .slick-slide {
            height: 60px;
            max-height: 60px;

            img {
              width: 100%;
              height: 60px;
              max-height: 60px;
            }
          }
        }
      }
    }
  }
  .slick-prev,
  .slick-next {
    z-index: 2;
    @include border-radius(50%);
    @include size($slick-arrow-width);
    @include transition(all 0.3s ease);
    @include size(40px);
    @include border-radius(50%);
    @include align-items(center);
    @include justify-content(center);
    @include transition(all 0.5s ease);
    display: flex !important;
    border: solid 1px rgba($black, 0.8) !important;
    background-color: rgba($black, 0.8) !important;
    @include box-shadow(0 4px 4px 0 rgba($black, 0.25));
    @include opacity(0);
    visibility: hidden;

    &:before {
      font-size: 0;
      color: $black;
      content: " ";
      direction: ltr;
      display: block;
      word-wrap: normal;
      white-space: nowrap;
      @include opacity(1);
      @include size(18px);
      @include transition(all 0.5s ease);
      -moz-osx-font-smoothing: grayscale;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' fill='%23fff'%3E%3Cpath d='M79.093 0L48.907 30.187 146.72 128l-97.813 97.813L79.093 256l128-128z'/%3E%3C/svg%3E");
      background-size: 18px auto;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover {
      border: solid 1px $hoverColor !important;
      background-color: $hoverColor !important;
      @include box-shadow(0 4px 4px 0 rgba($hoverColor, 0.25));

      &::before {
        color: $black;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' fill='%23000'%3E%3Cpath d='M79.093 0L48.907 30.187 146.72 128l-97.813 97.813L79.093 256l128-128z'/%3E%3C/svg%3E");
      }
    }
  }

  .slick-prev {
    left: 0;

    &:before {
      content: "\";
    }
  }
  .slick-next {
    right: 0;

    &:before {
      content: "\";
    }
  }
  .slick-prev {
    &::before {
      @include transform(rotate(180deg));
    }
  }

  .gallery--slider {
    &:hover {
      .slick-prev,
      .slick-next {
        @include opacity(1);
        visibility: visible;
      }

      .slick-prev {
        left: 25px;
      }
      .slick-next {
        right: 25px;
      }
    }
  }

  .gallery--slider__thumbs {
    .slick-prev,
    .slick-next {
      @include size(32px);

      &:before {
        @include size(16px);
        background-size: 16px auto;
      }
    }

    &:hover {
      .slick-prev,
      .slick-next {
        @include opacity(1);
        visibility: visible;
      }
      .slick-prev {
        left: 15px;
      }
      .slick-next {
        right: 15px;
      }
    }
  }
}

.seller--steps {
  &__card {
    background-color: $white_sh4;
    padding: 40px 20px !important;
    @extend .animation-all;

    @include responsive(sm) {
      padding: 50px 30px !important;

      @include respond-to(md) {
        padding: 70px 40px !important;

        @include respond-to(lg) {
          padding: 100px 50px !important;
        }
      }
    }

    &:nth-child(2) {
      background-color: darken($white_sh4, 7.5%);
    }
    &:nth-child(3) {
      background-color: darken($white_sh4, 15%);
    }
  }
  .container-fluid {
    @extend .no-maxwidth;
  }

  &__number {
    font-size: 100px;
    @extend .text-sec;
    line-height: 100px;
    font-weight: $weightUltrabold;
  }
  &__title {
    font-size: 22px;
    line-height: 1.5;
    font-weight: $weightSemibold;
  }
  &__excerpt {
    font-size: 16px;
    line-height: 1.5;
  }
  &__number,
  &__title,
  &__excerpt {
    color: $grey_sh1;
    text-shadow: 0 1px 0 rgba($white, 0.1);
  }
}
.seller--steps__card {
  .c-button {
    min-width: 210px;
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
    visibility: hidden;
    @include opacity(0);
  }

  &:hover,
  &:focus,
  &:active {
    background-color: darken($grey_sh2, 10%);

    .seller--steps__number,
    .seller--steps__title,
    .seller--steps__excerpt {
      color: $white;
      text-shadow: 0 1px 0 rgba($black, 0.1);
    }
    .c-button {
      color: $black;
      border-color: $white;
      background-color: $white;
      visibility: visible;
      @include opacity(1);
    }
  }
}

.share--reports {
  height: 0;
  width: 100%;
  position: relative;
  padding-bottom: 65%;

  iframe {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0 none;
    position: absolute;
    @include size(100%);
  }
}

.testimonials--slider {
  .featured--testimonials {
    &-item {
      height: 100%;
      max-width: 100%;
      @include flex-basis(100%);
      @include margin-bottom(0);

      @include breakpoint(700px) {
        max-width: 100%;
        @include flex-basis(100%);
      }
    }
  }
  .author {
    @include flexbox;
    @include align-items(center);
    @include justify-content(flex-start);

    .d-inline-flex {
      @include flex-wrap(wrap);
      span {
        width: 100%;
        display: block;
        &:first-child {
          font-size: 15px;
          font-weight: $weightSemibold;
        }
        // &:last-child {
        //   font-size: 13px;
        //   @include opacity(0.75);
        //   display: none !important;
        // }
      }
    }
  }
  .slick-list {
    pointer-events: none;
    @include padding-top(35);
  }
  .slick-track {
    pointer-events: all !important;
  }

  .slick-next,
  .slick-prev {
    top: -10px !important;
    background-color: $black;
  }
  .slick-prev,
  .slick-next {
    z-index: 2;
    @include flexbox;
    position: absolute;
    @include size(30px);
    font-size: 0 !important;
    @include border-radius(3px);
    @include align-items(center);
    @include justify-content(center);
    @include transition(all 0.5s ease);
    background-color: rgba($black, 0.8);
    display: flex !important;

    &::before {
      content: " ";
      color: $white;
      display: block;
      font-size: 16px;
      position: absolute;
      text-align: center;
      @include size(16px);
      @include transition(all 0.5s ease);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' fill='%23fff'%3E%3Cpath d='M79.093 0L48.907 30.187 146.72 128l-97.813 97.813L79.093 256l128-128z'/%3E%3C/svg%3E");
      background-size: 16px auto;
    }

    &:hover,
    &:focus,
    &:active {
      color: $black;
      background-color: $hoverColor;

      &::before {
        color: $black;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' fill='%23000'%3E%3Cpath d='M79.093 0L48.907 30.187 146.72 128l-97.813 97.813L79.093 256l128-128z'/%3E%3C/svg%3E");
      }
    }
  }
  .slick-prev {
    left: auto;
    right: 50px;

    @media (max-width: 767px) {
      left: auto;
      right: 50px;
    }
  }
  .slick-next {
    left: auto;
    right: 15px;

    @media (max-width: 767px) {
      right: 15px;
    }
  }
  .slick-prev {
    &::before {
      @include transform(rotate(180deg));
    }
  }

  .slick-dots {
    height: 8px;
    top: -0.75rem;
    bottom: auto !important;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    display: none !important;

    li {
      margin: 0 6px;
      @include size(8px);
      @include transition(all 0.5s ease);

      button {
        @include size(8px);
        @include padding(0);
        @include transition(all 0.5s ease);

        &::before {
          @include size(8px);
          font-size: 0 !important;
          background-color: $black;
          @include border-radius(5px);
          @include transition(all 0.5s ease);
        }
      }

      &.slick-active {
        width: 20px;

        button {
          width: 20px;

          &::before {
            width: 20px;
            background-color: $hoverColor;
          }
        }
      }
    }
  }
}

.read-more {
  color: $black;
  font-size: 1rem;
  line-height: 1.65;

  &__button {
    margin: 0;
    border: 0;
    padding: 0;
    outline: none;
    display: inline;
    font-size: 15px;
    cursor: pointer;
    color: $subprimary;
    @extend .animation-all;
    background-color: transparent;
    text-decoration: none !important;

    &:hover,
    &:focus,
    &:active {
      color: $blue_sh2;
    }
  }

  &__text--hide {
    font-size: 0;
    max-height: 0;
    @include opacity(0);
  }

  &__text--show {
    max-height: 10em;
    font-size: inherit;
    @include opacity(1);
  }

  &__text--remaining {
    @include transition(opacity 240ms ease);
  }
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-family: $primaryFontBold;
}

.section--content__inner {
  .h2,
  h2,
  .h3,
  h3,
  .h4,
  h4,
  .h5,
  h5,
  .h6,
  h6 {
    color: $black;
  }
  .h2,
  h2 {
    font-size: 1.5rem;
  }
  .h3,
  h3 {
    font-size: 1.25rem;
  }
  .h4,
  h4 {
    font-size: 1.15rem;
  }
}

.content-css {
  @extend .animation-all;
  span {
    margin-right: 2px;
  }
  p {
    &:last-child {
      margin-bottom: 0px !important;
    }
  }
}

.my-anchor-css-class {
  position: relative;
  color: $subprimary;
  display: inline-block;
  @extend .animation-all;
  background-color: transparent;
  text-decoration: none !important;

  &::before {
    width: 0;
    height: 1px;
    content: " ";
    bottom: 3px;
    position: absolute;
    @extend .animation-all;
    background-color: $subprimary;
  }

  &:hover,
  &:focus,
  &:active {
    color: $blue_sh2;

    &::before {
      width: 100%;
      background-color: $blue_sh2;
    }
  }
}

.section-header {
  h2 {
    font-family: $primaryFont;
  }
}

.card__details {
  h3 {
    font-family: $primaryFont;
  }
}

@include breakpoint(767) {
  .card--mobile {
    @include margin-bottom(30);
    height: calc(100% - 30px) !important;
  }
}

@include breakpoint(990.99) {
  .section--content__inner {
    .fs-md-26,
    .fs-md-26 {
      color: $black;
      font-size: 24px !important;
      font-family: $primaryFontBold;
    }
    .fs-md-32 {
      color: $black;
      font-size: 24px !important;
    }
  }
}

@include minbreakpoint(991) {
  .section--content__inner {
    .h2,
    h2 {
      font-size: 1.75rem;
    }
    .h3,
    h3 {
      font-size: 1.5rem;
    }
    .h4,
    h4 {
      font-size: 1.25rem;
    }
    p {
      @include margin-bottom(20);
    }
  }
  .p-6 {
    padding: 3.5rem !important;
  }
  .fs-md-26 {
    color: $black;
    font-size: 26px !important;
    font-family: $primaryFontBold;
  }
  .fs-md-32 {
    color: $black;
    font-size: 32px !important;
  }
  .lg-pl-100 {
    @include padding-left(100);
  }
  .md-text-right {
    text-align: right;
  }
}

// Firfox fixes
@-moz-document url-prefix() {
  .lead--btns {
    a,
    .u-button-pills {
      @include padding(8 20);
    }
  }
  .properties--details .nav-tabs > li > a {
    font-size: 1.125rem;
    @include padding(10 20);
  }
}

.h-features--properties {
  display: none !important;
}

.gallery--section {
  display: none !important;
  background-color: $white !important;
}
.meet--expert {
  background-color: lighten($offwhite, 5%);
  border-top: 1px solid lighten($border, 10%);
}
.s-features--properties {
  padding: 60px 0;
  background-color: $white;

  .properties--sold .features--properties-item-content .description {
    min-height: inherit !important;
    max-height: inherit !important;
  }
  .properties--sold .list-fx-features {
    padding-top: 10px;
    border-top: 1px solid $border;
    background-color: lighten($offwhite, 5%);
  }
}

.the-estates-at-lone-mountain {
  .hero--banner {
    height: 75vh;
    max-height: 75vh;

    &__body {
      max-height: 75vh;
    }
    .hero--content {
      padding-top: 4.5rem !important;
    }
  }
}

.vlr-gallery {
  .slick-prev::before,
  .slick-next::before {
    color: $yellow;
    font-size: 20px;
    opacity: 1 !important;
  }
  .slick-prev,
  .slick-next {
    z-index: 10;
    @include size(30px);
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }

  .vlr-thumbnail {
    .slick-track {
      margin-left: -2px;
      margin-right: -2px;
    }
    .slick-slide {
      padding: 2px;
      opacity: 0.5;

      &.slick-current {
        opacity: 1;
      }
    }
  }
}

.find-search {
  color: $black;
  @include padding(0);
  background-color: $black;
  @include border-radius(0.5rem);

  @media (max-width: 767px) {
    @include margin-bottom(45);
  }

  h2 {
    color: $white;
    font-size: 20px;
    @include padding(20);
    border-bottom: 1px solid rgba($white, 0.25);
  }

  &-inner {
    // overflow: hidden;
    @include padding(20);
    // @include padding-top(0);
    @include padding-bottom(30);

    label {
      color: $white;
    }
  }

  .form-group {
    z-index: 3;

    .input-group {
      width: auto;
      margin: 0 -20px;

      .form-control {
        color: $white;
        border: 0 none !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        padding-left: 1.375rem !important; /* 22px */
        background-color: transparent !important;
        border-bottom: 1px solid rgba($white, 0.25) !important;
      }

      button {
        transform: none !important;
        border-radius: 0 !important;
        border-color: darken($border, 10%) !important;
        background-color: darken($border, 10%) !important;
      }
    }
  }

  .c-button {
    position: inherit !important;
  }

  .css-7pwich-Input {
    color: $black;
    font-size: 14px;
    font-family: $secondaryFont;
    font-weight: $weightSemibold;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-tj5bde-Svg {
    fill: rgba($black, 0.75);
  }
  .css-1hb7zxy-IndicatorsContainer {
    padding-right: 0.25rem !important;
  }
  .css-1gtu0rj-indicatorContainer,
  .css-tlfecz-indicatorContainer {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .less-gutters {
    margin-left: -5px !important;
    margin-right: -5px !important;

    [class^="col-"] {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }

  .css-4ljt47-MenuList {
    max-height: 200px;
    > div {
      &:hover {
        color: $black;
        background-color: rgba($black, 0.1);
      }
    }
  }
  .css-1pahdxg-control {
    border-color: $border !important;
    box-shadow: 0 0 0 1px $border !important;
  }
}

.home--featured-lists {
  padding-top: 70px !important;
  padding-bottom: 0 !important;
  background-color: $white !important;

  .features--properties {
    &-item {
      max-width: 25%;
      @include flex-basis(25%);

      @include breakpoint(480) {
        max-width: 100%;
        @include flex-basis(100%);
      }

      @media screen and (min-width: 481px) and (max-width: 700px) {
        max-width: 50%;
        @include flex-basis(50%);
      }

      @media screen and (min-width: 701px) and (max-width: 991px) {
        max-width: 33.3333%;
        @include flex-basis(33.3333%);
      }
    }
  }
}

.bg-loading-button {
  margin-top: -50px !important;
  margin-bottom: -48px !important;
  padding-bottom: 70px !important;
  background-color: $white !important;
}

body.find {
  .features--properties-inner {
    .section-header {
      display: none !important;
    }
  }
}

/* End Custom Dropdown style(s) */
