/* Begin Radius style */

.u-radius {
    &--0 {
        @include border-radius(0px !important);
    }
    &--3 {
        @include border-radius(3px);
    }
    &--4 {
        @include border-radius(4px);
    }
    &--5 {
        @include border-radius(5px);
    }
    &--6 {
        @include border-radius(6px);
    }
    &--8 {
        @include border-radius(8px);
    }
    &--10 {
        @include border-radius(10px);
    }
    &--12 {
        @include border-radius(12px);
    }
    &--15 {
        @include border-radius(15px);
    }
    &--20 {
        @include border-radius(20px);
    }
    &--25 {
        @include border-radius(25px);
    }
    &--30 {
        @include border-radius(30px);
    }
    &--40 {
        @include border-radius(40px);
    }
    &--50 {
        @include border-radius(50px);
    }
    &--75 {
        @include border-radius(75px);
    }
    &--100 {
        @include border-radius(100px);
    }
    
    &--50xp {
        @include border-radius(50%);
    }
    
    &--100xp {
        @include border-radius(100%);
    }
}


/* //End Radius style */