/* Begin Margin & Padding style(s) */

w-60px {
    width: 60px;
}
.w-65 {
    max-width: 65%;
}
.w-75px {
    width: 75px;
}
.w-100px {
    width: 100px;
}
.w-130px {
    width: 130px;
}

/* Margins */
.no-margin-bottom {
    margin-bottom: 0 !important;
}
.margin-5px-bottom {
    margin-bottom: 5px;
}
.margin-10px-bottom {
    margin-bottom: 10px;
}
.margin-15px-bottom {
    margin-bottom: 15px;
}
.margin-20px-bottom {
    margin-bottom: 20px;
}
.margin-30px-bottom {
    margin-bottom: 30px;
}
.margin-20px-top {
    margin-top: 20px;
}
.margin-50px-top {
    margin-top: 50px;
}
.margin-five-bottom {
    margin-bottom: 5%;
}

.margin-1-rem-bottom {
    margin-bottom: 1rem;
}
.margin-1-half-rem-bottom {
    margin-bottom: 1.5rem;
}
.margin-2-rem-bottom {
    margin-bottom: 2rem;
}
.margin-2-half-rem-bottom {
    margin-bottom: 2.5rem
}
.margin-3-half-rem-bottom {
    margin-bottom: 3.5rem
}
.margin-4-rem-bottom {
    margin-bottom: 4rem
}
.margin-4-half-rem-bottom {
    margin-bottom: 4.5rem;
}
.margin-5-rem-bottom {
    margin-bottom: 5rem;
}
.margin-6-rem-bottom {
    margin-bottom: 6rem
}
.margin-35px-bottom {
    margin-bottom: 35px;
}
.margin-50px-bottom {
    margin-bottom: 50px
}

.margin-10px-right {
    margin-right: 10px;
}
.margin-25px-right {
    margin-right: 25px
}
.margin-5-half-rem-tb {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem
}
.margin-40px-left {
    margin-left: 40px;
}
.margin-60px-left {
    margin-left: 60px;
}
.margin-60px-right {
    margin-right: 60px
}

/* Paddings */
.padding-25px-left {
    padding-left: 25px;
}
.padding-15px-all {
    padding: 25px 15px;
}
.padding-40px-all {
    padding: 40px;
}
.padding-4-rem-all {
    padding: 4rem
}

.padding-40px-tb {
    padding-top: 40px;
    padding-bottom: 40px
}

@include breakpoint(767) {
    .sm-w-80 {
        max-width: 100%;
    }
    .sm-margin-20px-bottom {
        margin-bottom: 20px;
    }
    .sm-margin-30px-bottom {
        margin-bottom: 30px;
    }
    .margin-40px-left {
        margin-left: 20px;
    }
    .sm-no-padding-left {
        padding-left: 0;
    }
    .sm-margin-10px-bottom {
        margin-bottom: 10px !important;
    }
    .sm-margin-40px-bottom {
        margin-top: 35px;
        margin-bottom: 40px !important;
    }
    .sm-margin-10px-top {
        margin-top: 0.625rem !important; /* 10px */
    }
    .sm-margin-20px-top {
        margin-top: 1.25rem !important; /* 20px */
    }
    .sm-margin-30px-top {
        margin-top: 1.875rem !important; /* 30px */
    }
    .sm-margin-40px-top {
        margin-top: 2.5rem !important; /* 40px */
    }
    .sm-margin-50px-top {
        margin-top: 3.125rem !important; /* 50px */
    }
    .sm-margin-60px-top {
        margin-top: 3.75rem !important; /* 60px */
    }
}

/* End style(s) */
