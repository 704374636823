/* Begin Custom Dropdown style(s) */
.section--content,
.contact--forms {
  @include padding(40 0);
  background-color: $white;

  @include responsive(sm) {
    @include padding(55 0);

    @include respond-to(md) {
      @include padding(65 0);
    }
  }

  p {
    color: $black;
    line-height: 1.65;
  }

  .c-button {
    min-width: 160px !important;
  }
  .c-buttom-w200 {
    min-width: 200px !important;
    max-width: 200px !important;
  }

  &__inner {
    @include padding-right(0);

    @include responsive(sm) {
      @include padding-right(0);

      @include respond-to(md) {
        @include padding-right(50);
      }
    }
  }

  .video--block {
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    overflow: hidden;
    max-height: 400px;
    position: relative;

    video,
    iframe {
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      max-height: 100%;
      position: absolute;
      object-fit: cover;
      object-position: center;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}

.flex-order-1 {
  order: 1 !important;
}
.flex-order-2 {
  order: 2 !important;
}

@media (max-width: 767px) {
  .sm\:flex-order-1 {
    order: 2 !important;
  }
  .sm\:flex-order-2 {
    order: 1 !important;
  }
}

.Page--Content--With--Right--Sidebar {
  .section--content {
    &__inner {
      @include padding-left(0);
      @include padding-right(0);

      @include responsive(sm) {
        @include padding-left(0);
        @include padding-right(0);

        @include respond-to(md) {
          @include padding-left(0);
          @include padding-right(50);
        }
      }
    }
  }
}
.Page--Content--With--Left--Sidebar {
  .section--content {
    &__inner {
      @include padding-left(0);
      @include padding-right(0);

      @include responsive(sm) {
        @include padding-left(0);
        @include padding-right(0);

        @include respond-to(md) {
          @include padding-left(50);
          @include padding-right(0);
        }
      }
    }
  }
}

.contact--details {
  margin: 0;
  padding: 0;

  > li {
    margin: 0;
    padding: 0;
    list-style: none;
    @include margin-bottom(10);

    svg {
      color: $black;
    }

    a {
      color: $black;
      font-size: 16px;
      display: inline-block;

      &:hover,
      &:focus,
      &:active {
        color: $hoverColor;
      }
    }
  }
}

// Firfox fixes
@-moz-document url-prefix() {
}

/* End Custom Dropdown style(s) */
