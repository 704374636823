/* Begin Header style(s) */

.site-header {
  $headerSelf: &;

  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  position: fixed;
  @include padding(10 0);
  background-color: rgba($black, 0);

  @include responsive(sm) {
    height: auto;
    @include padding(10 0);

    @include respond-to(md) {
      height: auto;
      @include padding(15 0);
    }
  }

  .container-fluid {
    max-width: 100%;
    padding-left: 15px !important;
    padding-right: 15px !important;

    @include responsive(sm) {
      padding-left: 20px !important;
      padding-right: 20px !important;

      @include respond-to(md) {
        padding-left: 30px !important;
        padding-right: 30px !important;

        @include respond-to(lg) {
          padding-left: 40px !important;
          padding-right: 40px !important;
        }
      }
    }
  }

  .site-header-inner {
    color: $white;
    @include flexbox;
    @include flex-wrap(nowrap);
    @include align-items(center);
    @include justify-content(space-between);
    @include padding(0);
  }

  .page--logo {
    display: block;
    text-align: left;
    width: $logo-small-width;
    max-width: $logo-small-width;
    @include flex(1 1 $logo-small-width);
    -webkit-animation: 0.5s ease-in-out 0s normal none 1 running fadeInDown;
    animation: 0.5s ease-in-out 0s normal none 1 running fadeInDown;

    .img-fluid,
    img {
      width: 100%;
      max-width: 100%;
    }

    @include responsive(md) {
      display: block;
      width: $logo-width;
      max-width: $logo-width;
      @include flex(1 1 $logo-width);

      > a {
        width: 100%;
        display: block;
      }

      .img-fluid,
      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .header--number {
    color: $white;
    font-size: 14px;
    letter-spacing: 3px;
    @include padding-top(6);
    font-weight: $weightSemibold;

    @include breakpoint(419.99) {
      font-size: 13px;
      max-width: 120px;
      text-align: right;
      letter-spacing: 1px;
      // display: none;
    }
  }

  .btn-group {
    position: relative;

    @include breakpoint(419.99) {
      &.ml-4 {
        margin-left: 1rem !important;
      }
    }

    &::before {
      top: 50%;
      left: -10px;
      content: "";
      width: 1px;
      height: 25px;
      position: absolute;
      transform: translateY(-50%);
      background-color: rgba($white, 0.5);
    }

    .btn {
      height: auto !important;
      border: 0 none;
      @include padding(0);
      box-shadow: none !important;
      background-color: transparent !important;

      &:focus,
      &:active,
      &:hover {
        border: 0 none !important;
        background-color: transparent !important;
      }

      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      @include margin-top(14);
      padding: 0.25rem 0 0.125rem;
      @include border-radius(4px);
      @include box-shadow(0 2px 15px 3px rgba($black, 0.15));

      &::before {
        right: 10px;
        bottom: 100%;
        content: " ";
        @include size(0);
        position: absolute;
        pointer-events: none;
        border: solid transparent;

        border-color: rgba($white, 0);
        border-bottom-color: $white;
        border-width: 8px;
      }

      &-right {
        margin-right: -10px;
      }
    }
    .dropdown-item {
      @include flexbox;
      font-size: 15px;
      @include padding(6 15);
      @include margin-bottom(2);
      @include align-items(center);

      .text {
        @include padding-top(0);
      }

      .size20 {
        overflow: hidden;
        @include flexbox;
        @include size(20px);
        @include margin-right(6);
        @include border-radius(2px);
        @include align-items(center);
        @include justify-content(center);

        img {
          width: 20px;
          height: auto;
          display: block;
          object-fit: cover;
          object-position: center;
          @include border-radius(2px);
        }
      }
    }
  }

  &.header--sticky {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    @include padding(15 0);
    background-color: rgba($black_sh6, 0.95);
    @include box-shadow(0 4px 18px 0 rgba($black, 0.1));
    -webkit-animation: 0.5s ease-in-out 0s normal none 1 running fadeInDown;
    animation: 0.5s ease-in-out 0s normal none 1 running fadeInDown;

    @include breakpoint(767) {
      @include padding(8 0);
    }
  }
}

@keyframes fadeInDown {
  0% {
    @include opacity(0);
    @include transform(translateY(-20px));
  }
  100% {
    @include opacity(1);
    @include transform(translateY(0));
  }
}

.header--close-btn {
  top: 15px;
  right: 25px;
  width: 36px;
  height: 36px;
  font-size: 36px;
  @include flexbox;
  position: absolute;
  @include transform(scale(0));
  @include align-items(center);
  @include justify-content(center);
  @include transition(all 0.3s ease);

  svg {
    width: 36px;
    height: 36px;
    display: block;
    color: $white;
    @include transition(all 0.5s ease);
  }

  &:hover {
    svg {
      color: $grey_sh4;
    }
  }
}

.header-nav {
  top: 0;
  right: 0;
  width: $sidebar-width;
  height: 100%;
  z-index: 9999;
  overflow: auto;
  position: fixed;
  background: $black;
  @include padding(0 40);
  -webkit-overflow-scrolling: touch;
  @include transition(all 0.3s ease);
  @include transform(translate3d($sidebar-width, 0, 0));

  &.is-active {
    @include transform(translate3d(0, 0, 0));

    .header--close-btn {
      @include transform(scale(1));
    }
  }

  &--content {
    @include padding-top(65);
    @include padding-bottom(20);

    .page--logo {
      top: -35px;
      min-width: 200px;
      max-width: 200px;
      position: relative;

      @include breakpoint(767) {
        min-width: 180px;
        max-width: 180px;
      }
    }

    .footer--social {
      @include padding-top(40);
      .list-reset {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);

        li {
          &:first-child {
            @include margin-left(0);
          }
          a {
            color: $white;
            @include padding-left(0);

            &:hover {
              @include padding-left(0);
            }
          }
        }
      }
    }
  }

  @include breakpoint(767) {
    width: $card-width;
    max-width: $card-width;
  }
}

.menu--overlay {
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  cursor: pointer;
  visibility: hidden;
  @include opacity(0);
  @include transition(all 0.3s ease);
  background-color: rgba($black, 0.5);
}

.off-nav-is-active {
  overflow: hidden !important;

  .menu--overlay {
    display: block;
    visibility: visible;
    @include opacity(1);
  }
}

.header--nav {
  width: 100%;

  &__lists {
    list-style: none;
    @include margin(0);
    @include padding(0);
    @include padding-bottom(20);
    border-bottom: 1px solid rgba($white, 0.1);

    > li {
      @include margin-bottom(10);

      > a {
        width: 100%;
        color: $white;
        display: block;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        position: relative;
        @include padding(5 0);
        text-transform: uppercase;
        font-weight: $weightUltrabold;
        @include transition(all 0.5s ease);

        &:hover,
        &.is--active {
          color: $grey_sh4;
        }

        // &::before {
        //   left: 0;
        //   width: 0;
        //   bottom: 5px;
        //   height: 3px;
        //   content: " ";
        //   position: absolute;
        //   background-color: $black;
        //   @include transition(all 0.5s ease);
        // }

        &:hover {
          &::before {
            width: 100px;
            background-color: $grey_sh4;
          }
        }
      }

      > ul {
        @include margin-top(5);
        > li {
          @include margin-bottom(0);
          > a {
            width: 100%;
            color: $black;
            display: block;
            font-size: 15px;
            line-height: 24px;
            @include padding(5 0);
            text-transform: uppercase;
            font-weight: $weightUltrabold;
            @include transition(all 0.5s ease);

            &:hover,
            &.is--active {
              color: $grey_sh4;
              font-weight: $weightUltrabold;
            }
          }
        }
      }
    }
  }
}

/* End Header style(s) */
