$primary:       #49a7d9 !default;
$subprimary:    #2c82ae !default;
$primary_sh2:   #8017c1 !default;
$primary_sh3:   #c316ff !default;
$primary_sh4:   #ddd1f4 !default;

$primaryColor:  $primary !default;

$greyish:       #4F4F4F !default; /* #greyish-brown */
$grey:          #6C757D !default; /* #brownish-grey */
$grey_sh1:      #3B3B3B !default; /* #charcoal */
$grey_sh2:      #323232 !default; /* #cool-grey */
$grey_sh3:      #616161 !default; /* #cool-grey */
$grey_sh4:      #808284 !default; /* #cool-grey */
$black:         #000000 !default; /* #black */
$transparent:   rgba(0,0,0, 0) !default; /* #transparent */

$blue:          $primary !default; /* #sapphire */
$blue_sh1:      $subprimary !default; /* #cerulean */
$blue_sh2:      #0D4DF0 !default;
$blue_sh3:      #EBF4F8 !default;
$blue_sh4:      #243DFF !default;
$blue_sh5:      #4CDFC8 !default;
$blue_sh6:      #e8f3ff !default;
$info:          $blue_sh1 !default;

$card:          #19171c !default;
$modal:         #0F0F0F !default;

$dark:          #1d202c !default; /* #dark */

$red:           #ed1c38 !default; /* #neon-red */
$red_sh1:       #ff0f82 !default; /* #scarlet */
$red_sh2:       #ffdfee !default; /* #scarlet */
$error:         $red    !default; /* #error */

$green:         #00c48c !default; /* #green-apple */
$success:       #00c48c !default; /* #aqua-marine */
$green_sh1:     #55910b !default; /* #grass-green */
$green_sh2:     #50e3c2 !default; /* #greenish-cyan */
$green_sh3:     #039b7e !default; /* #teal */
$green_sh4:     #b4f000 !default; /* #lemon-green */
$hoverColor:    #E3C87D !default;
$theadColor:    #F0F0F0 !default;

$yellow:        $hoverColor !default; /* #sunflower-yellow */
$warning:       #ffded3 !default; /* #orangey-yellow */
$warning_sh1:   #ff8c69 !default;
$warning_sh2:   #ff9115 !default;
$yellow_sh1:    #fb8d29 !default; /* #pumpkin-orange */
$yellow_sh2:    #ff5500 !default; /* #bright-orange */

$white:         #FFFFFF !default; /* #white */
$white_sh1:     #F3F3F3 !default; /* #very-light-pink */
$white_sh2:     #F9F9F9 !default;
$white_sh3:     #F6F6F6 !default;
$white_sh4:     #CCCCCC !default;
$offwhite:      #E4E4E4 !default; /* #very-light-pink */

$border:        #C7CFD8 !default; /* #light-periwinkle */
$border_sh1:    #EBEBEB !default;
$iconColor:     #B1B1B1 !default;
$btnGroup:      #F2F2F2 !default;

$unsuccessful:  #FF3569 !default;
$blackred:      #AC305F !default;
$black_sh1:     #333333 !default;
$black_sh2:     lighten($black_sh1,20%) !default; /* color code == #666666 also (0,0,0,0.6) */
$black_sh3:     lighten($black_sh1,40%) !default; /* color code == #999999 */
$black_sh4:     lighten($black_sh1,60%) !default; /* color code == #cccccc */
$black_sh5:     lighten($black_sh1,41%) !default; /* color code == #9c9c9c */
$placeholder:   #C0C0C3 !default;
$black_sh6:     #231f20 !default;

$facebook:      #3B5998 !default;
$twitter:       #00AEEF !default;
$pinterest:     #BD081C !default;
$googleplus:    #E34829 !default;
$youtube:       #D62423 !default;
$vimeo:         #5BC7FF !default;
$instagram:     #fe1f49 !default;
$linkedin:      #1F7BC2 !default;
$flickr:        #377EDE !default;
$lightgrey:     #CDCDCD !default;
$lightborder:   #E0E0E0 !default;
$wechat:        #2DC100 !default;

$badgePrimary:  #E5E7Fa !default;
$badgePrimary_sh1: #E1F9F2 !default;


/* Widths ▸ Window Width */
$viewportSize:  100% !default;
$max-width:     1920px !default;

$fullHeight:    100vh !default;

/* Animation Speed */
$anim-speed:    500ms !default;

$general-transition-time:       300ms !default;

$slow-transition-time:          370ms !default;
$dropdown-coordinates:          29px -50px !default;

$fast-transition-time:          150ms !default;
$select-coordinates:            50% -40px !default;

$transition-linear:             linear !default;
$transition-bezier:             cubic-bezier(0.34, 1.61, 0.7, 1) !default;
$transition-ease:               ease 0s !default;


// Color system

$white:                         #FFFFFF !default;
$gray-100:                      #F6F9Fc !default;
$gray-200:                      #E9ECEF !default;
$gray-300:                      #E3E3E3 !default;
$gray-400:                      #CED4DA !default;
$gray-500:                      #ADB5BD !default;
$gray-600:                      #9D9D9D !default;   // Line footer color
$gray-700:                      #52505A !default;   // Line p color
$gray-800:                      #32325D !default;   // Line heading color
$gray-900:                      #262b35 !default;
$dark-gray:                     #848A9F !default;
$gray-1000:                     #3F3356 !default;

$dangerAlert:                   #FFE2F0 !default;
$sidebarColor:                  #F8F6FF !default;

.dark-gray {
    color: $dark-gray;
}
.dark-grey2 {
    color: $gray-900 !important;
}
.text-black {
    color: $black;
}
.color-primary {
    color: $primary_sh2;
}

// Sidebar variables
$logo-width:                    280px !default;
$logo-small-width:              130px !default;
$logo-large-width:              230px !default;
$logo-mini-width:               80px  !default;
$card-width:                    300px !default;
$sidebar-width:                 350px !default;

// border
$none:                          0   !default;
$border-thin:                   1px !default;
$border-thick:                  2px !default;
$border-width:                  .0625rem !default;
$border-color:                  $border !default;

$border-radius:                 0.375rem  !default;
$border-radius-xs:              0.1875rem !default;
$border-radius-sm:              0.2857rem !default;
$border-radius-lg:              0.4285rem !default;
$border-radius-xl:              0.875rem  !default;

// Shadow(s)
$box-shadow:                    0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
$box-shadow-sm:                 0 .125rem .25rem rgba($black, .075);
$box-shadow-lg:                 0 1rem 3rem rgba($black, .175);
$box-shadow-xs:                 0 2px 20px 0 rgba(0, 0, 0, 0.1);
$sidebar-box-shadow:            0px 2px 22px 0 rgba(0, 0, 0,.20), 0px 2px 30px 0 rgba(0, 0, 0,.35);

// Card(s)
$card-spacer-y:                 1rem    !default;
$card-spacer-x:                 1.5rem  !default;
$card-border-width:             $border-width !default;
$card-border-radius:            $border-radius-sm !default;
$card-border-color:             rgba($black, .05);
$card-inner-border-radius:      calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg:                   $gray-100 !default;
$card-bg:                       $white !default;
$card-black-background:         #27293d !default;
$card-img-overlay-padding:      1.25rem !default;

//$card-group-margin:           ($grid-gutter-width / 2);
//$card-deck-margin:            $card-group-margin !default;

$card-columns-count:            3 !default;
$card-columns-gap:              1.25rem !default;
$card-columns-margin:           $card-spacer-y !default;


$custom-control-indicator-disabled-bg:          $gray-200 !default;
$custom-control-indicator-checked-bg:           $green !default;
$custom-toggle-slider-bg:   #ddd !default;
$custom-toggle-checked-bg:  theme-color("primary") !default;
$input-transition:                      all .2s cubic-bezier(0.68, -0.55, 0.265, 1.55);


$topbar-x:                      topbar-x !default;
$topbar-back:                   topbar-back !default;
$bottombar-x:                   bottombar-x !default;
$bottombar-back:                bottombar-back !default;

// Dimensions

$screen-xs:                  767px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  991px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


/* Media Query Breakpoints */
$media-desktop: "screen and (max-width: 1199px)";
$media-ipadPortrait: "screen and (max-width :991px)";
$media-iPad: "screen and (min-width : 768px) and (max-width : 991px)";
$media-ipadSmall: "screen and (max-width :767px)";
$media-tablet: "screen and (max-width: 575px)";
$media-phone: "screen and (max-width : 480px)";
